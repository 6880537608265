import nodata from "../../../assets/common/nodata.png";

const NoData = (props: {
  description: String,
}) => {
  const { description } = props;
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <img className='w-20 h-20' src={nodata} alt="" />
      <div className='mt-4 text-gray-607 dark:text-gray-d2e-60 text-base'>{description}</div>
    </div>
  );
};

export default NoData;
