// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import {
  observable,
  action,
  makeAutoObservable,
  autorun,
  runInAction,
} from "mobx";
import { StepType, list as uiStepList } from "../stepList";
import {
  getCeloTasks,
  accomplishTask,
  getAllTaskStatus,
  CeloCommonParamsType,
} from "../../../api/airdropCeloApi";
import { CELO_TASK_TYPES } from "../../../config/celoTasks";

interface DetailInfo {
  bannerBackground: string;
  bannerBackgroundH5: string;
  content: string;
  links: {
    [key: string]: any;
  };
}
interface AccountInfo {
  account: string;
  phone: string;
  callCode: string;
  [key: string]: any;
}

const keys = {
  stepList: "celoairdrop-stepList",
  accountInfo: "celoairdrop-accountInfo",
  uiStepList: "celoairdrop-uiStepList",
  detailInfo: "celoairdrop-detailInfo",
};

class AirdropSessionStorage {
  static get(key: string) {
    return JSON.parse(
      window.sessionStorage.getItem(key) || JSON.stringify(null)
    );
  }
  static set(key: string, parmas: any) {
    window.sessionStorage.setItem(key, JSON.stringify(parmas));
  }
}

const storageDate = {
  stepList: AirdropSessionStorage.get(keys.stepList) || [],
  accountInfo: AirdropSessionStorage.get(keys.accountInfo),
  uiStepList: AirdropSessionStorage.get(keys.uiStepList) || uiStepList,
  detailInfo: AirdropSessionStorage.get(keys.detailInfo),
};

class CeloSteps {
  @observable.deep stepList: StepType[];
  @observable.deep accountInfo: AccountInfo;
  @observable.deep detailInfo: DetailInfo;
  @observable.deep uiStepList: StepType[];

  constructor() {
    makeAutoObservable(this);
    this.stepList = storageDate.stepList as StepType[];
    this.uiStepList = storageDate.uiStepList;
    this.accountInfo =
      storageDate.accountInfo ||
      ({
        account: "",
        phone: "",
        callCode: "",
        userStatus: { walletAddr: "" },
        timeStock: {
          endTs: "",
          remaining: 0,
          startTs: "0",
          total: 0,
        },
        activityId: "",
        terms: [],
      } as AccountInfo);
    this.detailInfo =
      storageDate.detailInfo ||
      ({
        bannerBackground: "",
        bannerBackgroundH5: "",
        links: {},
        content: "",
      } as DetailInfo);
  }

  @action setActivityId: (id: string) => Promise<void> = async (id: string) => {
    this.accountInfo["activityId"] = id;
  };
  @action setActivitySlotId: (id: string) => Promise<void> = async (
    id: string
  ) => {
    this.accountInfo["slotId"] = id;
  };

  @action setStepList: (params: CeloCommonParamsType) => Promise<any> = (
    params
  ) => {
    return getCeloTasks(params)
      .then((res: any) => {
        const {
          tasks,
          userStatus,
          timeStock,
          terms,
          content,
          bannerBackground,
          bannerBackgroundH5,
          links,
        } = res;
        runInAction(() => {
          this.stepList = tasks;
        });
        this.setAccountInfo("userStatus", userStatus);
        const transferTypeParams =
          this.stepList?.find((step) =>
            step.subType.includes(CELO_TASK_TYPES.transferType)
          )?.params || {};
        this.setAccountInfo("timeStock", {
          ...timeStock,
          symbol: transferTypeParams.coin_symbol || "",
          amount: transferTypeParams.coin_amount || "",
        });
        this.setAccountInfo("terms", terms);
        this.setDetailInfo("content", content);
        this.setDetailInfo("bannerBackground", bannerBackground);
        this.setDetailInfo("bannerBackgroundH5", bannerBackgroundH5);
        this.setDetailInfo("links", links);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  @action doingStep = (stepId: number) => {
    const _list = JSON.parse(JSON.stringify(this.uiStepList)).map(
      (item: { pending: boolean }) => {
        item.pending = false;
        return item;
      }
    );
    if (stepId === 1) {
      _list[0].pending = true;
    } else if (stepId === 2) {
      _list[0].pending = true;
      _list[1].pending = true;
    } else if (stepId === 3) {
      _list[0].pending = true;
      _list[1].pending = true;
      _list[2].pending = true;
    }

    this.uiStepList = _list;
  };

  @action accomplishStep1 = (account: string, signString: string) => {
    let target: any = this.stepList.find((val) =>
      val.subType.includes(CELO_TASK_TYPES.connectType)
    );
    return accomplishTask(target.taskId, {
      params: {
        gen_time: target?.params?.gen_time as string,
        wallet_addr: account,
        signed_msg: signString,
      },
    }).then((res: any) => {
      target.status = res.status;
      this.uiStepList[0].status = res.status;
      return res;
    });
  };

  @action accomplishStep2: (
    idToken: string,
    params: CeloCommonParamsType
  ) => Promise<any> = (idToken: string, params) => {
    let target: any = this.stepList.find((val) =>
      val.subType.includes(CELO_TASK_TYPES.firebaseType)
    );
    return accomplishTask(target.taskId, {
      params: {
        idToken: idToken,
        ...params,
      },
    }).then((res: any) => {
      target.status = res.status;
      this.uiStepList[1].status = res.status;
      return res;
    });
  };

  @action accomplishStep3: () => void = () => {
    let target: any = this.stepList.find((step) =>
      step.subType.includes(CELO_TASK_TYPES.transferType)
    );
    target.status = "checked";
  };

  @action setAllStatus: () => Promise<any> = () => {
    return getAllTaskStatus(this.accountInfo.activityId)
      .then((tasks) => {
        this.stepList.map((step) => {
          tasks.forEach((task: any) => {
            if (
              `${step.index}${step.taskId}${step.subType}` ===
              `${task.index}${task.taskId}${task.subType}`
            ) {
              step.status = task.status;
              step.finishTs = task.finishTs;
            }
          });
          return step;
        });
        return tasks;
      })
      .catch((err: any) => {
        console.warn(err);
      });
  };

  @action setAccountInfo = (
    accountKey:
      | "account"
      | "phone"
      | "callCode"
      | "userStatus"
      | "timeStock"
      | "activityId"
      | "terms"
      | "content",
    value: any
  ) => {
    this.accountInfo[accountKey] = value;
    // console.log(JSON.parse(JSON.stringify(this.accountInfo)));
  };
  @action setDetailInfo = (
    accountKey: "bannerBackground" | "content" | "bannerBackgroundH5" | "links",
    value: any
  ) => {
    this.detailInfo[accountKey] = value;
  };
}

const celoSteps = new CeloSteps();

autorun(() => {
  AirdropSessionStorage.set(keys.stepList, celoSteps.stepList);
  AirdropSessionStorage.set(keys.accountInfo, celoSteps.accountInfo);
  AirdropSessionStorage.set(keys.uiStepList, celoSteps.uiStepList);
  AirdropSessionStorage.set(keys.detailInfo, celoSteps.detailInfo);
  // console.log("Energy level:", JSON.stringify(celoSteps.stepList));
});

export default celoSteps;
