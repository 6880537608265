import axios from 'axios';
import qs from "qs";
const MAX_TIMEOUT = 120000;
axios.defaults.withCredentials = true
// before request
axios.interceptors.request.use(config => {
  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
  return config
}, error => {
  console.error('catch-interceptors-request:', error);
  return Promise.reject(error)
})

// before response
axios.interceptors.response.use(response => {
  return response
}, (error) => {
  //error handling
  switch (error.response.status) {
    case 401:
      //Maybe login failed
      break;
    default:
      break;
  }
  console.error('catch-interceptors-response:', error);
  return Promise.resolve(error.response)
})

//axios global config，such as: axios.defaults.headers.common['Authorization'] = 'xx';。
axios.defaults.timeout = MAX_TIMEOUT;

/**
 * get method
 * @param {string} url
 * @param {object} params
 * @param {object} headers
 * @return {promise}
 * @example get('http://xx.com',{a:1,b:3}).then(res => { console.log(res) }).catch(err => { console.log(err) })
 */
const get = (url = '', params = {}, headers = {}) => {
  return axios({
    method: 'get',
    url,
    headers: { ...headers },
    params: Object.assign({}, params),
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    },
  })
}

/**
 * post method
 * @param {string} url
 * @param {object} params
 * @param {object} headers
 * @return {promise}
 * @example post('http://xx.com',{a:1,b:3}).then(res => { console.log(res) }).catch(err => { console.log(err) })
 */
const post = (url = '', params = {}, headers = {}) => {
  return axios({
    method: 'post',
    url,
    headers: { ...headers },
    data: Object.assign({}, params)
  });
}

export {
  get,
  post
}
