// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

const Progress = (props:{
    rate:string
})=>{
    const {rate} = props;
    return (
        <div className="relative w-full h-10px bg-gray-000012 rounded">
            <span className="absolute h-10px rounded bg-gray-000087 left-0 top-o" style={{width:`${rate}`}}></span>
        </div>
    )
}

export default Progress