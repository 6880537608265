/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { get } from "../api/http";
import { campaigns } from "../api/api";
import { errorCallback, successCallback } from "./constants";
import { commonParams } from "../api/common";

const getCampaigns = (params?: any) => {
  return get(campaigns, { ...params, ...commonParams })
    .then(successCallback)
    .catch(errorCallback);
};

export { getCampaigns };
