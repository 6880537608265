import { useEffect, useState } from "react";
import moment from "moment";

export interface ICountdown {
  deadline: string;
  format?: "YYYY-MM-DD HH:mm:ss" | string;
}
export type Remains = Record<"day" | "hour" | "minute" | "second", number>;

const useCountdown = ({
  deadline,
  format = "YYYY-MM-DD HH:mm:ss",
}: ICountdown): Remains => {
  const [{ current, updater }, setCurrent] = useState({
    current: moment(),
    updater: 0,
  });
  const [remains, setRemains] = useState<Remains>({
    day: 0,
    hour: 0,
    minute: 0,
    second: 1,
  });
  useEffect(() => {
    const timer = window.setInterval(() => {
      current.isSameOrAfter(moment(deadline, format))
        ? clearInterval(timer)
        : setCurrent((prev) => ({
            current: prev.current.add(1, "s"),
            updater: prev.updater + 1,
          }));
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadline]);

  useEffect(() => {
    let millisec = moment(deadline, format).valueOf() - current.valueOf();
    millisec = millisec >= 0 ? millisec : 0;
    setRemains({
      day: Math.floor(millisec / (1000 * 60 * 60 * 24)),
      hour: Math.floor((millisec / (1000 * 60 * 60)) % 24),
      minute: Math.floor((millisec / (1000 * 60)) % 60),
      second: Math.round((millisec / 1000) % 60),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updater]);

  return remains;
};

export default useCountdown;
