import TaskCard from "./components/TaskCard";
import Download from "./components/Download";
import { useEffect, useState } from "react";
import { getCampaign } from "../../models/task";
import type { CampaignResp, Links, Task, UserStatus } from "./Type";
import { formatDate } from "../../utils/formatDate";
import { UPCOMING, ONGOING, ENDED } from "./Constants";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import "./Task.css";
import { useTranslation } from "react-i18next";
import { isOperaBrowser } from "../../utils/platform";

// const htmlStr = `
// <div>
//   <h3 class="text-xl font-medium mt-10">The prize pool</h3>
//   <div class="opacity-60 text-14-22 mt-4">
//     60,000 $FIO tokens will be awarded to 510 randomly selected
//     winners across two different prize pools! 500 winners: 100 Fio
//     each 10 grand prize winners: 1000 Fio each
//   </div>
// </div>
// <div>
//   <h3 class="text-xl font-medium mt-10">About FIO Protocol</h3>
//   <div class="opacity-60 text-14-22 mt-4">
//     Kaiju Legends is a community-driven collectibles project, while
//     building our own ecosystem around the metaverse. The first wave
//     is a Ethereum collection of 7777 generated Kaiju that will
//     arrive on the 15th of August, in collaboration with Crypto.com.
//     A colorful multiverse where art, story, toys and gaming come
//     together. Kaiju Legends is an interactive community project that
//     centers around blockchain technology.
//   </div>
// </div>`;
const Airdrop = () => {
  const { t } = useTranslation();

  const [detail, setDetail] = useState<CampaignResp>();
  const [status, setStatus] = useState<string>("");

  const params = useParams();
  const getCampaignData = () => {
    getCampaign({ campaignId: params.id }).then((res: any) => {
      const data = res?.data || {};
      setDetail(data);
      if (!data.userStatus.twitterSceenName) {
        Cookies.remove("twitter_name");
      }
      if (data.startTimestamp) {
        const now = new Date().getTime();
        if (now < data.startTimestamp * 1000) {
          setStatus(UPCOMING);
        } else if (now < data.endTimestamp * 1000) {
          setStatus(ONGOING);
        } else {
          setStatus(ENDED);
        }
      }
    });
  };

  useEffect(() => {
    getCampaignData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="pc:w-0.8 mx-auto pc:mt-5 h5:px-4 h5:pt-4">
      <img
        className="task-banner h5:hidden"
        src={detail?.bannerBackground}
        alt=""
      />
      {/* <div className="h-48 bg-no-repeat bg-cover bg-center" style={{backgroundImage: `url(${banner})`}} ></div> */}
      <div className="flex pc:w-0.67 pc:max-w-1160 mx-auto mb-14">
        <div className="flex-1">
          <div className="flex items-center mt-6 mb-7">
            <img className="w-15 h-15 mr-3" src={detail?.projectLogo} alt="" />
            <div>
              <div className="text-2xl font-bold uppercase">
                {detail?.projectName}
              </div>
              <span
                className={`rounded px-2 text-sm font-bold ${
                  status === UPCOMING
                    ? "bg-25518 text-orange-ff8"
                    : status === ONGOING
                    ? "bg-rgba811 text-green-51b"
                    : "bg-rgba212 text-red-d52"
                }`}
              >
                {t(`common.${status.toLowerCase()}`)}
              </span>
            </div>
          </div>
          <div className="flex rounded-xl pb-8 h5:pb-5 px-6 flex-wrap bg-rgba81114">
            <div className="pc:w-33% h5:w-50% mt-8 h5:mt-5 pc:text-left">
              <div className="text-sm h5:text-xs opacity-60">
                {t("common.amount")}
              </div>
              <div className="text-base font-bold my-2 h5:my-1">
                {detail?.amount}
              </div>
              <div className="text-sm h5:text-xs">
                {detail?.winers}
                {detail?.winers === 1
                  ? ` ${t("common.winner")}`
                  : ` ${t("common.winners")}`}
              </div>
            </div>
            <div className="pc:w-33% h5:w-50% mt-8 h5:mt-5 pc:text-center">
              <div className="text-sm h5:text-xs opacity-60">
                {t("common.begins")}
              </div>
              <div className="text-base font-bold my-2 h5:my-1">
                {formatDate(detail?.startTimestamp as string)}
              </div>
            </div>
            <div className="pc:w-33% h5:w-50% mt-8 h5:mt-5 pc:text-right">
              <div className="text-sm h5:text-xs opacity-60">
                {t("common.ends")}
              </div>
              <div className="text-base font-bold my-2 h5:my-1">
                {formatDate(detail?.endTimestamp as string)}
              </div>
            </div>
          </div>
          <div className="pc:hidden">
            {isOperaBrowser || !detail?.uaProtect ? (
              <TaskCard
                status={status}
                getCampaignData={getCampaignData}
                startTimestamp={detail?.startTimestamp as string}
                endTimestamp={detail?.endTimestamp as string}
                userStatus={detail?.userStatus as UserStatus}
                tasks={detail?.tasks as Task[]}
                needKyc={detail?.needKyc as boolean}
                isRefpage={detail?.isRefpage as boolean}
              />
            ) : (
              <Download links={detail?.links as Links} />
            )}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: detail?.content as string }}
          ></div>

          <div className="flex mt-7">
            {detail?.links.website && (
              <a
                className="mr-7"
                target="_blank"
                rel="noopener noreferrer"
                href={detail?.links.website}
              >
                <div className="w-6 h-6 desktop bg-no-repeat bg-contain"></div>
              </a>
            )}
            {detail?.links.discord && (
              <a
                className="mr-7"
                target="_blank"
                rel="noopener noreferrer"
                href={detail?.links.discord}
              >
                <div className="w-6 h-6 discord bg-no-repeat bg-contain"></div>
              </a>
            )}
            {detail?.links.telegram && (
              <a
                className="mr-7"
                target="_blank"
                rel="noopener noreferrer"
                href={detail?.links.telegram}
              >
                <div className="w-6 h-6 telegram bg-no-repeat bg-contain"></div>
              </a>
            )}
            {detail?.links.twitter && (
              <a
                className="mr-7"
                target="_blank"
                rel="noopener noreferrer"
                href={detail?.links.twitter}
              >
                <div className="w-6 h-6 twitter bg-no-repeat bg-contain"></div>
              </a>
            )}
          </div>
          <div className="mt-11 opacity-60 flex items-center ">
            {detail?.terms.map((t, i, arr) => (
              <a
                className="inline-block text-xs underline hover:text-green-0ed hover:opacity-100"
                href={t.link}
                target="_blank"
                rel="noreferrer"
              >
                {t.text}
                {i < arr.length - 1 && (
                  <div className="inline-block border-l border-black h-3 mx-3"></div>
                )}
              </a>
            ))}
          </div>
        </div>
        <div className="h5:hidden">
          {isOperaBrowser || !detail?.uaProtect ? (
            <TaskCard
              status={status}
              getCampaignData={getCampaignData}
              startTimestamp={detail?.startTimestamp as string}
              endTimestamp={detail?.endTimestamp as string}
              userStatus={detail?.userStatus as UserStatus}
              tasks={detail?.tasks as Task[]}
              needKyc={detail?.needKyc as boolean}
              isRefpage={detail?.isRefpage as boolean}
            />
          ) : (
            <Download links={detail?.links as Links} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Airdrop;
