import React, { useState, useEffect, useRef } from 'react';
import { getCampaigns } from "../../models/list";
import Tab from "./components/Tab";
import NoData from "./components/NoData";
import Loading from "./components/Loading";
import ActiveItem from "./components/ActiveItem";
import { TabItem, ActiveData } from "./Interface";
import { getQueriesFromSearch } from "../../utils/url"
import { useTranslation } from 'react-i18next';
const List = () => {
  const { t } = useTranslation();

  const tabArray: TabItem[] = [
    { name: t("common.all"), id: "all" },
    { name: t("common.ongoing"), id: "ongoing" },
    { name: t("common.upcoming"), id: "upcoming" },
    { name: t("common.ended"), id: "ended" },
  ];
  const [selectId, setSelectId] = useState(tabArray[0].id);
  const [dataList, setDataList] = useState<ActiveData[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const more = useRef(false);
  const list = useRef<ActiveData[]>([]);
  const [pageNum, setPageNum] = useState(0);
  const pageNumBySelectId = useRef(false);

  const modifySelectId = (id: string) => {
    setSelectId(id);
  };
  const getCampaignsList = (first = false) => {
    getCampaigns({
      filter: selectId,
      pageSize: 12,
      pageNum: pageNum,
    }).then((res: any) => {
      setPageLoading(false);
      if (res?.nextPageNum > 0) {
        setLoadingMore(true);
        more.current = true;
      } else {
        setLoadingMore(false);
        more.current = false;
      }
      if (first) {
        list.current = res?.data;
      } else {
        list.current = [...list.current, ...res?.data];
      }
      setDataList(list.current);
    });
  }

  useEffect(() => {
    setPageLoading(true);
    setDataList([]);
    more.current = false;
    setLoadingMore(false);
    if (pageNum === 0) {
      getCampaignsList(true);
    } else {
      pageNumBySelectId.current = true;
      setPageNum(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectId]);
  useEffect(() => {
    if (pageNum === 0) {
      if (pageNumBySelectId.current === true) {
        getCampaignsList(true);
      }
    } else {
      getCampaignsList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum]);


  const loadingMoreRef = useRef<HTMLDivElement>(null);
  const observerRef: any = useRef();

  useEffect(() => {
    const query = getQueriesFromSearch(window.location.search);
    if (query?.style === "light") {
      document.documentElement.classList.remove('dark');
    } else {
      document.documentElement.classList.add('dark');
    }

    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && more.current) {
        setPageNum(pageNum => pageNum += 1)
      }
    });
    observerRef.current.observe(loadingMoreRef.current);

    return () => {
      document.documentElement.classList.remove('dark');
      observerRef.current?.disconnect();
    }
  }, []);

  return (
    <div>
      <Tab
        tabArray={tabArray}
        selectId={selectId}
        modifySelectId={modifySelectId}
      ></Tab>
      <div>{
        dataList.length > 0 ?
          <div className='pl-4 pr-2 pt-1.5 flex h5:flex-col pc:flex-wrap'>{
            dataList.map((item: ActiveData, index: Number) => {
              return <ActiveItem
                key={item.campaignId + '_' + index}
                item={item}
              ></ActiveItem>
            })
          }</div>
          : pageLoading ? <div className='h-header pt-36'><Loading></Loading></div>
          :<div className="pt-36">{
            <NoData description={"New airdrop coming soon!"} />
          }</div>
      }</div>
      <div style={{ display: loadingMore ? 'block' : 'none' }} ref={loadingMoreRef}>
        <Loading></Loading>
      </div>

    </div>
  );
};

export default List;
