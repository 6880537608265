// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import i18n from "../locale/i18n"
import { log } from "../utils/log";

const  {t}= i18n;

export const errConfig = Object.freeze({
  "auth/captcha-check-failed": t("errorMessage.refresh"),
  "auth/invalid-phone-number": t("errorMessage.invaldPhone"),
  "auth/missing-phone-number": t("errorMessage.missPhone"),
  "auth/quota-exceeded": t("errorMessage.sysErr"),
  "auth/user-disabled": t("errorMessage.phoneBlock"),
  "auth/operation-not-allowed": t("errorMessage.sysErr"),
  "auth/invalid-app-credential": t("errorMessage.refresh"),
  "auth/code-expired": t("errorMessage.expiredCode"),
  "auth/invalid-verification-code": t("errorMessage.invalidVerCode"),
});

function getErrorMap(): Map<string, string> {
  return new Map(Object.entries(errConfig));
}

const errMap = getErrorMap();

export function getErrorMsg(msg: string): string {
  log("firebase_err", { message: msg });
  let _msg = errMap.get("auth/quota-exceeded") || "";
  errMap.forEach((val, key) => {
    if (msg.toLowerCase().includes(key)) {
      _msg = val;
      return;
    }
  });

  return _msg;
}
