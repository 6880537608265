/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { get, post } from "../api/http";
import { campaign, accomplish, winers, countries, kyc } from "../api/api";
import { errorCallback, successCallback } from "./constants";
import { commonParams } from "../api/common";

const getCampaign = (params?: any) => {
  return get(campaign(params?.campaignId || ""), {
    ...params,
    ...commonParams,
  })
    .then(successCallback)
    .catch(errorCallback);
};

const postAccomplish = (params?: any) => {
  return post(accomplish(params?.taskId || ""), {
    ...params,
    ...commonParams,
  })
    .then(successCallback)
    .catch(errorCallback);
};

const getWiners = (params?: any) => {
  return get(winers(params?.campaignId || ""), { ...params, ...commonParams })
    .then(successCallback)
    .catch(errorCallback);
};

// const getUA = (params?: any) => {
//   return get(("https://echo.opera.com"), { ...params })
//     .then(successCallback)
//     .catch(errorCallback);
// };
const getCountries = () => {
  return get(countries, { ...commonParams })
    .then(successCallback)
    .catch(errorCallback);
};
const postKyc = (params?: any) => {
  return post(kyc, { ...params, ...commonParams })
    .then(successCallback)
    .catch(errorCallback);
};

export { getCampaign, postAccomplish, getWiners, getCountries, postKyc };
