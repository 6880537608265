import { isOperaMiniBrowser, UA } from "../../utils/platform";

const Test = () => {
  return (
    <div className="text-center text-lg pt-10">
      <a href="opera-mini://crypto_wallet" className="text-blue-500 border p-3"><button> Click to test mini deeplink</button></a>
      <div className="mt-6 text-red-400">ua result: {isOperaMiniBrowser ? "in mini" : "not in mini"} </div>
      <div>ua message detail: {UA}</div>
    </div>
  );
};

export default Test;
