import success from "../../../assets/icons/success.svg";
import close from "../../../assets/icons/close.svg";
import fail from "../../../assets/icons/fail.svg";
import triangle from "../../../assets/icons/triangle.svg";
import activate from "../../../assets/common/activate.png";
import Web3 from "web3";
import { postAccomplish } from "../../../models/task";
import type { Task } from "../Type";
import { TASK_STATUS } from "../Constants";
import { useState } from "react";
import Cookies from "js-cookie";
import { isOperaMiniBrowser } from "../../../utils/platform";

declare global {
  interface Window {
    ethereum: any;
  }
}
const Connect = (props: {
  task: Task;
  onlyFunc?: boolean;
  changeStatus: Function;
  complateTask: Function;
}) => {
  const miniWalletLink = "opera-mini://crypto_wallet";
  const { task, changeStatus, complateTask, onlyFunc } = props;

  const [status, setStatus] = useState(
    task.status === TASK_STATUS.CHECKED ? TASK_STATUS.CHECKED : TASK_STATUS.NULL
  );
  const params: any = task.params;
  const [showActivate, setShowActivate] = useState(false);
  const handleConnect = async () => {
    try {
      const timer = setTimeout(() => {
        if (isOperaMiniBrowser) {
          window.open(miniWalletLink);
        } else {
          setShowActivate(true);
        }
      }, 3000);
      await window.ethereum.enable();
      clearTimeout(timer);
      if (typeof window.ethereum == "undefined") {
        console.log("Wallet is not installed!");
      }
      const web3 = new Web3(window.ethereum);
      const message = params?.sign_msg;
      const addresses = await web3.eth.requestAccounts();
      console.log("address", addresses);
      const reloadPage = () => {
        Cookies.remove("airdrop-session");
        window.location.reload();
      };
      window.ethereum.on("accountsChanged", reloadPage);
      window.ethereum.on("disconnect", reloadPage);
      const signature = await web3.eth.personal.sign(message, addresses[0], "");

      console.log("signature", signature);
      const teskRes = await postAccomplish({
        taskId: task.taskId,
        params: {
          gen_time: params?.gen_time,
          wallet_addr: addresses[0],
          signed_msg: signature,
        },
      });
      console.log(teskRes);
      if (teskRes?.data.status === TASK_STATUS.CHECKED) {
        setStatus(TASK_STATUS.CHECKED);
        setShowActivate(false);
        complateTask(task?.taskId);
        changeStatus(true);
      }
      return addresses[0];
    } catch (e) {
      console.log(e);
      if (isOperaMiniBrowser) {
        window.open(miniWalletLink);
      } else {
        setShowActivate(true);
      }
      return e;
    }
  };
  return (
    <div>
      {onlyFunc ? (
        <div className="mt-6" onClick={handleConnect}>
          <span className="mr-2 text-base"> 👉 </span>
          <span className=" text-xs font-bold underline cursor-pointer hover:text-green-0ed">
          View Result
          </span>{" "}
        </div>
      ) : (
        <div className="mb-5 flex text-base">
          <div className="flex-1 relative">
            <div
              onClick={handleConnect}
              dangerouslySetInnerHTML={{ __html: params?.rich_text as string }}
            ></div>
            {/* <div>
          <span className="font-bold">1.&nbsp;</span>Connect
          <img src={wallet} className="mx-1 inline" alt="" />
          <span
            onClick={handleConnect}
            className="font-bold underline decoration-1 mr-1 hover:text-green-0ed cursor-pointer"
          >
            Opera Wallet
          </span>
        </div> */}
            {showActivate && (
              <div className=" h-32 bg-black-2a3 w-72 rounded-lg absolute top-10 left-4 z-10">
                <div className=" overflow-hidden">
                  <img
                    className=" absolute -top-3 left-36 "
                    src={triangle}
                    alt=""
                  />
                  <img
                    className=" float-right mt-3 mr-3 cursor-pointer"
                    onClick={() => {
                      setShowActivate(false);
                    }}
                    src={close}
                    alt=""
                  />
                </div>
                <img
                  src={activate}
                  className="w-12 h-12 mb-4 mx-auto -mt-1"
                  alt=""
                />
                <div className="text-center text-xs text-white-fff px-4 pb-6">
                  Please activate Opera Wallet and use it to connect to the
                  website
                </div>
              </div>
            )}
          </div>
          {status === TASK_STATUS.CHECKED && <img src={success} alt="" />}
          {status === TASK_STATUS.UNCHECKED && <img src={fail} alt="" />}
        </div>
      )}
    </div>
  );
};

export default Connect;
