// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import promo from "../../assets/celoimages/promo.svg";
import cbsvg from "../../assets/common/android_mini.svg";
import androidSvg from "../../assets/common/andr.png";
import { useTranslation } from "react-i18next";
import { default as store } from "./mobx/CeloSteps";
import { observer } from "mobx-react";

const appDowloadUrl =
  "https://play.google.com/store/apps/details?id=com.opera.mini.native&referrer=af_tran?utm_id=airdorp&utm_campaign=celo";

const CeloAirdrop = () => {
  const { t } = useTranslation();
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 pc:bg-promoGradient">
      <div
        className="fixed top-0 left-0 right-0 bottom-0 overflow-y-auto flex flex-col items-center mx-auto px-3 
      pc:max-w-404 pc:mt-24px bg-white-fff pc:border-4 pc:mb-80px"
      >
        <div className="font-semibold mt-32">
          {t("views.celoairdrop.title", {
            coin_symbol: store.accountInfo?.timeStock?.symbol,
          })}
        </div>
        <img src={promo} className="mt-5" alt="step 1" />
        <h3 className="font-semibold text-20 mt-24 text-center">
          {t("views.guide.title2", {
            coin_amount: `${store?.accountInfo?.timeStock?.amount} ${store.accountInfo.timeStock.symbol}`,
          })}
        </h3>
        <div className="text-14-22 flex text-left mt-1">
          {t("views.guide.title3", {
            coin_amount: `${store?.accountInfo?.timeStock?.amount} ${store.accountInfo.timeStock.symbol}`,
          })}
        </div>
        <div className="bg-blue-DFF0FD px-3 flex flex-col items-center py-4 mt-10 rounded-md mb-16px">
          <img className="w-50px" src={cbsvg} alt="crypto" />
          <div className="text-center text-12 text-black-100 my-2">
            {t("views.guide.content")}
          </div>
          <div className="flex justify-center items-center">
            <a href={appDowloadUrl}>
              <img className="w-28" src={androidSvg} alt="google" />
            </a>{" "}
            {/* The mini browser does not support ios*/}
            {/* &nbsp;&nbsp;
          <a href="https://apps.apple.com/app/id1604311726">
            <img className="w-28" src={iosSvg} alt="ios" />
          </a> */}
          </div>
        </div>
        {/* <i
        className="icon-close-gray absolute right-5 top-5"
        style={{ fontSize: 14 }}
        onClick={() => {
          navigate("/celo/step1", { replace: true });
        }}
      ></i> */}
      </div>
    </div>
  );
};

export default observer(CeloAirdrop);
