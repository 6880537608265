import loading from "../../../assets/common/loading.webp";

const Loading = () => {
  return (
    <div className="flex items-center justify-center w-full box-border">
      <img className="w-6 h-6 m-2 animate-rotate" src={loading} alt="loading" />
    </div>
  );
};

export default Loading;
