const Error404 = () => {
  return (
    <div className="text-center text-lg pt-48">
      <h1 className="text-6xl">404</h1>
      <div>Page not found</div>
    </div>
  );
};

export default Error404;
