const Domain = process.env.REACT_APP_DOMAIN;

// GET
const campaign = (campaignId: string) => `${Domain}/campaign/${campaignId}`;
// POST
const accomplish = (taskId: string) =>
  `${Domain}/participant/task/${taskId}/accomplish`;

// GET
const winers = (campaignId: string) =>
  `${Domain}/campaign/${campaignId}/winers`;

const campaigns = `${Domain}/api/campaigns`;

// GET
const countries = `${Domain}/api/countries`;
//POST
const kyc = `${Domain}/api/user/kyc`;

export { campaign, accomplish, winers, campaigns, countries, kyc };
