import { BrowserRouter, Routes, Route } from "react-router-dom";
import Airdrop from "./views/Task/Task";
import List from "./views/list/List";
import Test from "./views/Test/Test";
import Error404 from "./views/404/404";
import CeloAirdrop from "./views/CeloAirdrop/CeloAirdrop";
import Landing from "./views/CeloAirdrop/Landing";
import Guide from "./views/CeloAirdrop/Guide";
import CeloSuccessful from "./views/CeloAirdrop/Succcessful";
import InputPhone from "./views/CeloAirdrop/InputPhone";
import SmsVerify from "./views/CeloAirdrop/SmsVerify";

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/airdrop/:id" element={<Airdrop />} />
            <Route path="/giveaway/:id" element={<Airdrop />} />
            <Route path="/airdrop" element={<List />} />
            <Route path="/test" element={<Test />} />
            <Route path="/404" element={<Error404 />} />
            <Route path="/celo/step2/smsverify/:id/:subId" element={<SmsVerify />} />
            <Route path="/celo/guide" element={<Guide />} />
            <Route path="/celo/:id/:subId" element={<CeloAirdrop />} />
            <Route path="/celo/landing/:id/:subId" element={<Landing />} />
            <Route path="/celo/step2/:id/:subId" element={<InputPhone />} />
            <Route path="/celo/step3/:id/:subId" element={<CeloSuccessful />} />
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
};

export default App;
