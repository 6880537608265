// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import axios from "axios";
import { log } from "../utils/log";
import { commonParams } from "./common";
const Domain = process.env.REACT_APP_DOMAIN;

axios.defaults.withCredentials = true;

const apexcrypto = axios.create({
  baseURL: Domain,
  params: commonParams,
  // baseURL: "/api",
});

apexcrypto.interceptors.response.use(response => {
  return response
}, (error) => {
  //error handling
  switch (error.response.status) {
    case 400:
      window.location.href = "/404";
      break;
    default:
      break;
  }
  console.error('catch-interceptors-response:', error);
  return Promise.reject(error.response)
})

export interface CeloCommonParamsType {
  id?: string;
  slot: string;
  signature: string;
}
export function getCeloTasks(params: CeloCommonParamsType) {
  return apexcrypto
    .get(`/campaign/${params.id}`, {
      params,
    })
    .then((res) => {
      return res.data.data;
    });
}

export function accomplishTask(
  taskId: string,
  parmas: {
    params: {
      gen_time?: string;
      wallet_addr?: string;
      signed_msg?: string;
      idToken?: string;
      rich_text?: string;
    };
  }
) {
  return apexcrypto
    .post(`/participant/task/${taskId}/accomplish`, parmas)
    .then((res) => {
      return res.data.data;
    });
}

export function getClentInfo(regions: string) {
  return apexcrypto.get(`/api/client/info?regions=${regions}`).then((res) => {
    return res.data.data;
  });
}

export function presend(parmas: {
  campaign_id?: string;
  phone_number?: string;
}) {
  log("presend", { message: "sended" });
  return apexcrypto
    .post(`/api/sms/presend`, parmas)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      const error = err.data;
      log("presend_err", { message: error?.data?.message || "error" });
      return err;
    });
}

export function getCountries(regions: string): Promise<any> {
  return apexcrypto
    .get(`/api/countries?expandCallCode=true&regions=${regions}`)
    .then((res) => {
      return res.data.data;
    });
}

export function getAllTaskStatus(activityId: string): Promise<any> {
  return apexcrypto
    .get(`/participant/campaign/${activityId}/status`)
    .then((res) => {
      return res.data.data;
    });
}
