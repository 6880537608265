// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import { StepType } from "../stepList";
import { observer } from "mobx-react";
import { default as store } from "../mobx/CeloSteps";
import { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { CELO_TASK_TYPES } from "../../../config/celoTasks";

const StepItem = observer((props: { step: StepType }) => {
  const { step } = props;
  const borderLeftTag =
    step.id === 2 ? (
      <span
        className={`absolute h-px w-2/6 left-0 z-0 top-12px ${
          step.pending ? "bg-black-262728" : "bg-gray-e0e0e0"
        }`}
      ></span>
    ) : (
      <></>
    );
  const borderRightTag =
    step.id === 2 ? (
      <span
        className={`absolute h-px w-2/6 right-0 z-0 top-12px ${
          step.pending ? "bg-black-262728" : "bg-gray-e0e0e0"
        }`}
      ></span>
    ) : (
      <></>
    );

  return (
    <div
      className={`flex flex-col items-center ${
        step.id === 2 ? "relative px-14" : ""
      }`}
    >
      <div
        className={`w-8 h-8 flex justify-center items-center  rounded-full text-12 ${
          step.pending ? "bg-black-262728 text-white-fff" : "bg-gray-e0e0e0"
        }`}
      >
        {borderLeftTag}
        {borderRightTag}
        {(step.id === 3 && step.pending) || step.status === "checked" ? (
          <i className="icon-success_white" style={{ fontSize: 12 }}></i>
        ) : (
          step.id
        )}
      </div>
      <span className="text-12 mt-4px">{step.text}</span>
    </div>
  );
});

const Step = (props: { step: number }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, subId } = useParams();
  const { step } = props;

  const query = new URLSearchParams(location.search);
  const checkOldAddress = () => {
    store.stepList?.forEach((val: any) => {
      if (
        store.accountInfo.userStatus?.walletAddr ===
          store.accountInfo.account &&
        val.status === "checked"
      ) {
        if (val.subType.includes(CELO_TASK_TYPES.firebaseType)) {
          navigate(
            `/celo/step3/${id}/${subId}/?signature=${query.get("signature")}`,
            {
              replace: true,
            }
          );
        } else if (
          val.subType.includes(CELO_TASK_TYPES.connectType) &&
          location.pathname === `/celo/${store.accountInfo.activityId}/${store.accountInfo.slotId}` &&
          store.accountInfo.timeStock.remaining > 0
        ) {
          navigate(
            `/celo/step2/${id}/${subId}/?signature=${query.get("signature")}`,
            {
              replace: true,
            }
          );
        }
        return;
      }
    });
  };

  const getAllInfo = async () => {
    if (step === 1 && id) {
      await store.setActivityId(id);
      await store.setActivitySlotId(subId as string);
    }

    await store.setStepList({
      id: id as string,
      slot: subId as string,
      signature: query.get("signature") as string,
    });
    store.setAllStatus();
    checkOldAddress();
  };

  useEffect(() => {
    getAllInfo();
    store.doingStep(step);
    // eslint-disable-next-line
  }, [step, navigate]);

  const listItems = store.uiStepList.map((step: any) => (
    <StepItem step={step} key={step.id}></StepItem>
  ));
  return <div className="flex flex-row justify-around mt-24">{listItems}</div>;
};

export default observer(Step);
