// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import { Step, BottomSheet, Progress } from "./components";
import { isConnected, request } from "../../connect/wallet";
import step1Imag from "../../assets/celoimages/step1.svg";
import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { default as store } from "./mobx/CeloSteps";
import activate from "../../assets/common/activate.png";
import { observer } from "mobx-react";
import Web3 from "web3";
import { useTranslation } from "react-i18next";
import { isOperaBrowser, isOperaMiniBrowser } from "../../utils/platform";
import { CELO_TASK_TYPES } from "../../config/celoTasks";
import { log } from "../../utils/log";

const CeloAirdrop = () => {
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [btnText, setSetBtnText] = useState("Got it");
  const { t } = useTranslation();
  const [connectedAndNoAvailable, setConnectedAndNoAvailable] = useState(false);
  const [tips, showTips] = useState(false);
  const navigate = useNavigate();
  const ruoteParams = useParams();
  const bottomSheetRef: any = useRef<HTMLDivElement | null>();
  let web3: Web3;
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const reloadPage = () => {
    window.location.reload();
  };

  const getAccount = async () => {
    let address = "";
    await request("eth_requestAccounts")
      .then((res) => {
        address = window.ethereum.selectedAddress || res[0];
        store.setAccountInfo("account", address);
      })
      .catch((error) => {
        console.warn("getAccount error:", error);
        log("connect_error", {
          message: "CONNECT_ERROR:" + JSON.stringify(error),
          isInMini: isOperaMiniBrowser,
        });
        // showTips(true);
        if (isOperaMiniBrowser) {
          window.location.href = "opera-mini://crypto_wallet";
          // reloadPage();
        }
      });
  };

  const connectWallet = async () => {
    log("click_connect", { message: "clicked" });
    setLoading(true);
    await getAccount();
    signAccount(
      store.stepList?.find((val) =>
        val.subType.includes(CELO_TASK_TYPES.connectType)
      )?.params.sign_msg
    );
  };

  useEffect(() => {
    if (!isOperaBrowser) {
      navigate("/celo/guide", { replace: true });
    }
    document.title = t("views.celoairdrop.rowTitle");
    try {
      window.ethereum.on("disconnect", reloadPage);
      // window.ethereum.on("accountsChanged", reloadPage);
    } catch (error) {
      console.warn(error);
    }
    let address = "";
    isConnected()
      .then((res) => {
        if (res) {
          address = window.ethereum.selectedAddress || "";
          if (address) {
            store.setAccountInfo("account", address);
          }
        }
      })
      .catch((err) => {
        console.warn(err);
      });
    // eslint-disable-next-line
  }, []);

  const signAccount = async (inpuStr: string) => {
    if (!web3) {
      web3 = new Web3(window.ethereum);
    }
    let signature = "";
    try {
      signature = await web3.eth.personal.sign(
        inpuStr,
        store.accountInfo.account,
        ""
      );
    } catch (error) {
      console.warn("signAccount error:", error);
      log("connect_error", {
        message: "SIGN_ERROR:" + JSON.stringify(error),
        isInMini: isOperaMiniBrowser,
      });
      // showTips(true);
      if (isOperaMiniBrowser) {
        window.location.href = "opera-mini://crypto_wallet";
        // reloadPage();
      }
      setLoading(false);
      return;
    }
    setLoading(true);
    log("connect_success_1", {
      message: "connect and sign success, before call the accomplish API.",
    });
    store
      .accomplishStep1(store.accountInfo.account, signature)
      .then((res: any) => {
        if (res.status === "checked") {
          if (
            rateNum() === "0.00%" ||
            new Date().getTime() > store.accountInfo.timeStock.endTs * 1000
          ) {
            // hide button show alet text
            setConnectedAndNoAvailable(true);
            setErrMsg(t("views.celoairdrop.progressTip") as string);
            bottomSheetRef?.current?.showBottomSheet();
          } else {
            log("connect_success_2", {
              message:
                "Call the accomplish API success and will jump to /step2",
            });
            navigate(
              `/celo/step2/${ruoteParams.id}/${
                ruoteParams.subId
              }/?signature=${query.get("signature")}`,
              {
                replace: true,
              }
            );
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 413) {
          // SIGN_MSG_EXPIRRED
          setErrMsg(t("views.celoairdrop.errtext2") as string);
          setTimeout(() => {
            reloadPage();
          }, 3000);
          setSetBtnText(t("views.celoairdrop.btnRefresh") as string);
        } else if (rateNum() === "0.00%") {
          setErrMsg(t("views.celoairdrop.progressTip") as string);
        } else if (err.response.status === 414) {
          // NOT_NEW_WALLET
          setErrMsg(t("views.celoairdrop.errtext1") as string);
          setSetBtnText(t("views.celoairdrop.btnGotIt") as string);
        } else {
          setErrMsg(t("errorMessage.refresh") as string);
        }

        bottomSheetRef?.current?.showBottomSheet();
      })
      .finally(() => {
        setLoading(false);
      });
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const accountFilter = (account: string) => {
    if (!account) return "";
    return account.slice(0, 8) + "..." + account.slice(-8);
  };

  const rate = () => {
    return (
      parseInt(
        `${
          ((store.accountInfo.timeStock.total -
            store.accountInfo.timeStock.remaining) /
            store.accountInfo.timeStock.total) *
          100
        }`
      ) + "%"
    );
  };

  const rateNum = () => {
    return (
      (
        (store.accountInfo.timeStock.total === 0
          ? 0
          : store.accountInfo.timeStock.remaining /
            store.accountInfo.timeStock.total) * 100
      ).toFixed(2) + "%"
    );
  };
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 pc:bg-promoGradient">
      <div
        className="fixed overflow-y-auto top-0 left-0 right-0 bottom-0 m-auto px-3 flex flex-col
     items-center pc:max-w-404 pc:mt-24px bg-white-fff pc:border-4 pc:mb-80px"
      >
        <div className="font-semibold mt-32">
          {t("views.celoairdrop.title", {
            coin_symbol: store.accountInfo?.timeStock?.symbol,
          })}
        </div>
        <Step step={1}></Step>
        <img src={step1Imag} className="mt-5" alt="step 1" />
        <h3 className="font-semibold text-20 mt-24 text-center">
          {t("views.celoairdrop.step1titl")}
        </h3>
        <div className="text-14-22 flex p-3 text-left">
          {t("views.celoairdrop.texttop", {
            coin_amount: `${store?.accountInfo?.timeStock?.amount} ${store.accountInfo.timeStock.symbol}`,
          })}
        </div>
        <div className="text-14-22 flex p-3 text-left">
          {t("views.celoairdrop.text2", {
            coin_symbol: store.accountInfo?.timeStock?.symbol,
          })}
        </div>
        <div className="w-full flex flex-col justify-end flex-1 mb-16px">
          <div>
            <div className="flex justify-between text-blue-2E54FF">
              <span>&nbsp;{t("views.celoairdrop.progressText")}</span>
              <span>{rateNum()}&nbsp;</span>
            </div>
            <div className={`mt-9px ${rate() === "100%" ? "mb-8" : "mb-32px"}`}>
              <Progress rate={rate()}></Progress>
            </div>
            {rate() === "100%" ? (
              <div className="text-gray-000087 mb-24px">
                {t("views.celoairdrop.progressTip")}
              </div>
            ) : (
              <></>
            )}
          </div>
          {!connectedAndNoAvailable && (
            <button
              className={`h-48px align-middle rounded-full text-14-16
      text-white-fff flex justify-center items-center w-full
      ${
        !store.stepList?.find((step) =>
          step.subType.includes(CELO_TASK_TYPES.connectType)
        )?.params?.sign_msg
          ? "bg-gray-f0f1f2 text-gray-000038"
          : "bg-black-100 text-white-fff"
      }
      bg-black-0d0d0d pc:max-w-624`}
              disabled={
                loading ||
                !store.stepList?.find((step) =>
                  step.subType.includes(CELO_TASK_TYPES.connectType)
                )?.params?.sign_msg
              }
              onClick={connectWallet}
            >
              {!loading ? (
                <>
                  <i className="text-white-fff icon-wallet_white mr-8"></i>
                  {t("views.celoairdrop.btntext")}
                </>
              ) : (
                <i className="icon-loading animate-spin"></i>
              )}
            </button>
          )}
          {store.accountInfo.terms && store.accountInfo.terms[0]?.link ? (
            <div
              className="flex mt-24px text-12 text-blue-2E54FF justify-center"
              onClick={() => {
                window.open(
                  store.accountInfo.terms && store.accountInfo.terms[0]?.link
                );
              }}
            >
              {store.accountInfo.terms && store.accountInfo.terms[0]?.text}
            </div>
          ) : (
            <></>
          )}
        </div>
        <BottomSheet ref={bottomSheetRef}>
          <div>
            <h4 className="font-semibold text-base">
              {t("views.celoairdrop.oops")}
            </h4>
            <div className="flex rounded-lg bg-gray-00008 items-center px-3 my-2 py-2">
              <i className="icon-wallet_white mr-8"></i>&nbsp;
              <span className="text-sm">
                {accountFilter(store.accountInfo.account)}
              </span>
            </div>
            <div className="text-sm mb-3">{errMsg}</div>
            <button
              className="w-full mb-3 rounded-full 
          mt-2 justify-center items-center 
          bg-black-100 text-14-16 h-48px align-middle
           text-white-fff
           "
              onClick={() => {
                bottomSheetRef?.current?.closeBottomSheet();
                if (btnText !== t("views.celoairdrop.btnRefresh")) {
                  window.close();
                }
              }}
            >
              {btnText}
            </button>
          </div>
        </BottomSheet>
        {tips ? (
          <div
            className="fixed z-10 bottom-0 top-0 left-0 right-0 
      flex justify-center items-center bg-gray-00006"
          >
            <div className=" bg-black-2a3 w-72 rounded-lg relative">
              <i
                className="icon-close absolute -right-3 -top-8"
                onClick={() => {
                  showTips(false);
                }}
              ></i>
              <img
                src={activate}
                className="w-12 h-12 mb-4 mx-auto mt-1"
                alt=""
              />
              <div className="text-center text-xs text-white-fff px-4 pb-6">
                {t("views.celoairdrop.tips")}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default observer(CeloAirdrop);
