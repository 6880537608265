/**
 * Format timestamp to August 8, 15:00
 * @param timestamp number
 */
const addZero = (num: number) => {
  if (num < 10 && num >= 0) {
    return `0${num}`;
  } else {
    return num;
  }
};

const numberMatchMonth = new Map([
  [1, "January"],
  [2, "February"],
  [3, "March"],
  [4, "April"],
  [5, "May"],
  [6, "June"],
  [7, "July"],
  [8, "August"],
  [9, "September"],
  [10, "October"],
  [11, "November"],
  [12, "December"],
]);

export const formatDate = (timestamp: string) => {
  const targetTime = new Date(Number(timestamp) * 1000),
    hour = targetTime.getHours(),
    minute = targetTime.getMinutes(),
    timeArr = targetTime.toString().split(" ");
  return `${timeArr[1]} ${timeArr[2]} ${addZero(hour)}:${addZero(minute)}`;
};

export const formatDateToMonthAndDay = (timestamp: string) => {
  const targetTime = new Date(Number(timestamp) * 1000),
    timeArr = targetTime.toString().split(" ");
  return `${timeArr[2]}th ${numberMatchMonth.get(targetTime.getMonth() + 1)}`;
};

export const formatDateToDay = (timestamp: string) => {
  const targetTime = new Date(Number(timestamp) * 1000),
    timeArr = targetTime.toString().split(" ");
  return `${timeArr[2]}`;
};

export const formatDateWithYear = (timestamp: string) => {
  const targetTime = new Date(Number(timestamp) * 1000),
    year = targetTime.getFullYear(),
    hour = targetTime.getHours(),
    minute = targetTime.getMinutes(),
    timeArr = targetTime.toString().split(" ");
  return `${year} ${timeArr[1]} ${timeArr[2]}, ${addZero(hour)}:${addZero(
    minute
  )}`;
};

export const formatDateWithMonthtoMinute = (timestamp: string) => {
  const targetTime = new Date(Number(timestamp) * 1000),
    hour = targetTime.getHours(),
    minute = targetTime.getMinutes(),
    month = targetTime.getMonth(),
    day = targetTime.getDate();
  return `${month>8?month+1:'0'+(month+1)}-${day>9?day:'0'+day} ${hour>9?hour:'0'+hour}:${minute>9?minute:'0'+minute}`;
};

export const countTime = (start: number, end: number) => {
  let interval = end - start;
  let day, hour, minute, second;

  interval /= 1000;

  day = Math.floor(interval / 60 / 60 / 24);
  hour = Math.floor((interval / 60 / 60) % 24);
  minute = Math.floor((interval / 60) % 60);
  second = Math.floor(interval % 60);

  return {
    day: day,
    hour: hour,
    minute: minute,
    second: second,
  };
};
