import { useEffect, useState } from "react";
import GeneralInput from "./inputs/GeneralInput";
import SearchInput from "./inputs/SearchInput";
import DateInput from "./inputs/DateInput";
import close from "../../../assets/icons/close-gray.svg";
import { getCountries, postKyc } from "../../../models/task";
import type { VerifyInfo } from "../Type";
import { useTranslation } from "react-i18next";

const VerifiedForm = (props: {
  addr: string;
  cancle: Function;
  getCampaignData: Function;
}) => {
  const { addr, cancle, getCampaignData } = props;
  const { t } = useTranslation();
  const [countryList, setCountryList] = useState([]);
  const [verifyInfo, setVerifyInfo] = useState<VerifyInfo>({
    countryCode: "",
    firstName: "",
    midName: "",
    lastName: "",
    birthday: "",
  });
  const [canSubmit, setCanSubmit] = useState(false);
  const [hasSubmit, setHasSubmit] = useState(false);
  useEffect(() => {
    getCountries().then((res) => {
      setCountryList(res.data);
    });
  }, []);
  const changeVerifyInfo = (key: keyof VerifyInfo, value: string) => {
    const info: VerifyInfo = { ...verifyInfo };
    info[key] = value;
    setVerifyInfo(info);
  };
  useEffect(() => {
    checkCanSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyInfo]);
  const checkCanSubmit = () => {
    const cantSubmit = Object.values(verifyInfo).some((value) => {
      return !value;
    });
    setCanSubmit(!cantSubmit);
  };
  const submitKyc = () => {
    if (!canSubmit) {
      return;
    }
    postKyc(verifyInfo)
      .then(() => {
        getCampaignData();
        setHasSubmit(true);
      })
      .catch(() => {
        setHasSubmit(false);
        alert("Connection error, please try again later.");
        //cancle();
      });
  };
  return (
    <div className="w-full h-full bg-black-mask fixed top-0 left-0 flex justify-center items-center z-50">
      <div className="h5:w-screen h5:h-screen px-4 pc:px-16 py-12 bg-white-fff text-black-100 pc:rounded-lg relative flex flex-col">
        <img
          src={close}
          className="absolute top-6 right-6 cursor-pointer"
          alt="close"
          onClick={() => {
            cancle();
          }}
        />
        {!hasSubmit && (
          <div className="flex-1">
            <div className="mb-2 text-xl leading-5 font-bold  text-center">
              {t("common.personalInformation")}
            </div>
            <div className="pc:max-w-400 mb-9 text-center text-sm">
              {t("common.yourInformationTips")}
            </div>
            <SearchInput
              inputTitle={t("common.nationality")}
              placeholder="Country / Region"
              fieldName="countryCode"
              countryList={countryList}
              changeCountry={changeVerifyInfo}
            />
            <div className="mt-4 flex">
              <div className="mr-4 flex-1">
                <GeneralInput
                  inputTitle={t("common.firstName")}
                  fieldName="firstName"
                  changeInput={changeVerifyInfo}
                />
              </div>
              <GeneralInput
                inputTitle={t("common.lastName")}
                fieldName="lastName"
                changeInput={changeVerifyInfo}
              />
            </div>
            <div className="mt-4 flex">
              <div className="mr-4 flex-1">
                <GeneralInput
                  inputTitle={t("common.middleName")}
                  fieldName="midName"
                  changeInput={changeVerifyInfo}
                />
              </div>
              <DateInput
                inputTitle={t("common.dateOfBirth")}
                fieldName="birthday"
                placeholder="YYYY-MM-DD"
                changeInput={changeVerifyInfo}
              />
            </div>
          </div>
        )}
        {hasSubmit && (
          <div className="w-full flex flex-col  items-center flex-1">
            <div className="text-4xl leading-6 pt-12 pb-8">✅</div>
            <div className="pb-7 font-bold text-xl leading-5">
              {t("common.informationUploaded")}
            </div>
          </div>
        )}
        <div
          onClick={() => {
            hasSubmit ? cancle() : submitKyc();
          }}
          className={[
            "h-15",
            "mt-9",
            "text-white-fff",
            "text-lg",
            "font-bold",
            "rounded-lg",
            "flex",
            "items-center",
            "justify-center",
            canSubmit
              ? "bg-green-0ed cursor-pointer"
              : "bg-white-d8d cursor-not-allowed",
          ].join(" ")}
        >
          {!hasSubmit ? t("common.verify") : t("common.done")}
        </div>
        <div className="mt-4 text-sm text-center">
          {t("common.walletAddress")}:
          {addr.length > 10 ? `${addr.slice(0, 5)}...${addr.slice(-5)}` : addr}
        </div>
      </div>
    </div>
  );
};

export default VerifiedForm;
