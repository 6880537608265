import { AxiosResponse } from "axios";

export enum StatusCode {
  SUCCESS = 200,
}

export const successCallback = ({ status, data }: AxiosResponse) => {
  if (status === StatusCode.SUCCESS) {
    return data;
  }
  return undefined;
};

export const errorCallback = (error: AxiosResponse) => {
  const { data = {} } = error;
  console.error("http-err:", data);
  return data;
};
