// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.
import {
  ReactNode,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

const BottomSheet = (
  props: { children: ReactNode },
  ref: React.Ref<unknown> | undefined
) => {
  const [showing, setShowing] = useState(false);
  const bottomSheetRef = useRef<HTMLDivElement | null>(null);

  const displayEvent = (e: any) => {
    if (bottomSheetRef && !bottomSheetRef.current?.contains(e.target)) {
      setShowing(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", displayEvent, true);
    return () => {
      window.removeEventListener("click", displayEvent, true);
    };
  });

  useImperativeHandle(ref, () => ({
    closeBottomSheet: () => {
      setShowing(false);
    },
    showBottomSheet: () => {
      setShowing(true);
    },
  }));

  const { children } = props;
  if (showing) {
    return (
      <div
        className="fixed z-20 left-0 top-0 right-0 bottom-0 bg-gray-000032
      flex flex-col justify-end m-auto pc:max-w-404 pc:mt-24px pc:mb-80px"
      >
        <div
          className="rounded-t-2xl bg-white-fff px-3 pt-4"
          ref={bottomSheetRef}
        >
          {children}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default forwardRef(BottomSheet);
