import { TabItem } from "../Interface";

const Tab = (props: {
  tabArray: TabItem[],
  selectId: String,
  modifySelectId: Function,
}) => {
  const { tabArray, selectId, modifySelectId } = props;
  return (
    <div className="z-20 h-12 w-full">
      <div className={`flex z-50 fixed left-0 top-0 w-full items-center px-4 bg-white-fff dark:bg-black-0f1`}>
        {
          tabArray.map((item: TabItem, index: Number) => {
            return <div key={item.id + '_' + index} onClick={(e) => modifySelectId(item.id)} className={`cursor-pointer box-border highlight-color-none h-12 py-4 text-xs mr-4 capitalize relative focus:bg-bg-white-fff focus:dark:bg-black-0f1 bg-white-fff select-none dark:bg-black-0f1 ${item.id === selectId ? ' font-bold' : 'text-gray-607 dark:text-gray-7d9'}`}>
              {
                <>
                  <div>{item.name}</div>
                  {
                    item.id === selectId ?
                      <div className='absolute left-1/2 bottom-2.5 transform -translate-x-1/2 bg-green-00c h-1 rounded-sm w-6'></div>
                      : null
                  }
                </>
              }
            </div>
          })
        }
      </div>
    </div>
  );
};

export default Tab;
