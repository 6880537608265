import success from "../../../assets/icons/success.svg";
import fail from "../../../assets/icons/fail.svg";
import loading from "../../../assets/icons/loading.svg";
import type { Task, UserStatus } from "../Type";
import { useState } from "react";
import { postAccomplish } from "../../../models/task";
import { TASK_STATUS } from "../Constants";
import { useTranslation } from "react-i18next";

const Swap = (props: {
  task: Task;
  userStatus: UserStatus;
  complateTask: Function;
  endTimestamp: string;
  startTimestamp: string;
}) => {
  const { t } = useTranslation();
  const { task, complateTask } = props;
  const [status, setStatus] = useState(
    task.status === TASK_STATUS.CHECKED
      ? TASK_STATUS.CHECKED
      : TASK_STATUS.VERIFING
  );
  const params: any = task.params;

  const handleVerify = async () => {
    setStatus(TASK_STATUS.LOADING);
    const teskRes = await postAccomplish({
      taskId: task.taskId,
    });
    console.log(teskRes);
    if (teskRes?.data.status === TASK_STATUS.CHECKED) {
      setStatus(TASK_STATUS.CHECKED);
      complateTask(task?.taskId);
    } else {
      setStatus(TASK_STATUS.UNCHECKED);
      setTimeout(() => {
        setStatus(TASK_STATUS.VERIFING);
      }, 3000);
    }
  };

  return (
    <div className="mb-5 flex text-base">
      <div
        className="flex-1"
        dangerouslySetInnerHTML={{ __html: params?.rich_text as string }}
      ></div>
      {/* <div className="flex-1">
        <span className="font-bold">{id}.&nbsp;</span>Make&nbsp;
        {params.min_trades} {params.min_trades === "1" ? "Swap" : "Swaps"} in the
        <img src={wallet} className="mx-1 inline" alt="" />
        <span className="font-bold decoration-1 mr-1 ">Opera Wallet</span>
        using the 1inch Swap Service, with the total trading volume amount equal
        to or exceeding {params.min_amount} USD during the week competition time
        period.
      </div> */}
      {status === TASK_STATUS.UNCHECKED && <img src={fail} alt="" />}
      {status === TASK_STATUS.CHECKED && <img src={success} alt="" />}
      {status === TASK_STATUS.LOADING && <img src={loading} className="animate-spin" alt="" />}
      {status === TASK_STATUS.VERIFING && (
        <button
          onClick={handleVerify}
          className="text-white-fff px-2 h-6 bg-green-0ed text-xs rounded hover:bg-green-00c"
        >
          {t("common.verify")}
        </button>
      )}
    </div>
  );
};

export default Swap;
