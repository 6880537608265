// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import en from "./en.json";
import de from "./de.json";
import es from "./es.json";
import fr from "./fr.json";
import pl from "./pl.json";
import pt_BR from "./pt-BR.json";

const lang = {
  en: { translation: en },
  de: { translation: de },
  es: { translation: es },
  fr: { translation: fr },
  pl: { translation: pl },
  "pt-BR": { translation: pt_BR },
};

export default lang;
