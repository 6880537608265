// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  Auth,
  ConfirmationResult,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { log } from "./log";

const firebaseConfig = {
  apiKey: "AIzaSyCbGnff_b27G-YMDDwYmv9POW-tNwa6ADo",
  authDomain: "airdrop-6ca45.firebaseapp.com",
  projectId: "airdrop-6ca45",
  storageBucket: "airdrop-6ca45.appspot.com",
  messagingSenderId: "178425515898",
  appId: "1:178425515898:web:e49135246d5694648b50c3",
  // measurementId: "G-GEMCRTSFD9",
};

function firebaseOpt() {
  let opt: {
    app: any;
    auth: Auth;
    confirmationResult: ConfirmationResult;
  } = {
    app: null,
    auth: {} as Auth,
    confirmationResult: {} as ConfirmationResult,
  };
  return function () {
    if (!opt.app || !opt.auth) {
      const app: any = initializeApp(firebaseConfig);
      console.log("app", app);

      // eslint-disable-next-line
      // const analytics = getAnalytics(app);
      initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
          "6LcWCEojAAAAAO_trBuxgw_vEATP19IfdkPujlJW"
        ),
        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true,
      });
      // console.log("initializeAppCxheck", initializeAppCheck);
      // console.log("_app", _app);
      

      const auth = getAuth(app);
      auth.languageCode = navigator.language;
      opt.app = app;
      opt.auth = auth;
    }
    return opt;
  };
}

const getFirebase = firebaseOpt();

export const createBot = (containerName: string, phone: string) => {
  const frebase = getFirebase();
  let container: HTMLElement;
  let recaptchaContainer = document.getElementById("recaptcha-container");
  const verifyContainer = document.getElementsByClassName(containerName)[0];
  if (recaptchaContainer || !verifyContainer) {
    throw new Error("Failed to create bot");
  }
  container = document.createElement("div");
  container.id = "recaptcha-container";
  container.setAttribute(
    "style",
    `
    z-index:10;
    position:fixed;
    justify-content:center;
    align-items:center;
    display:flex;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(0,0,0,0.8);
  `
  );
  verifyContainer.appendChild(container);
  console.log("RecaptchaVerifier", RecaptchaVerifier);

  const appVerifier = new RecaptchaVerifier(
    "recaptcha-container",
    {
      size: "100",
      callback: (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
        // console.log("response", response);
      },
      "expired-callback": () => {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      },
    },
    frebase.auth
  );
  return signInWithPhoneNumber(frebase.auth, phone, appVerifier)
    .then((_confirmationResult: ConfirmationResult) => {
      frebase.confirmationResult = _confirmationResult;
      window.confirmationResult = _confirmationResult;
      return _confirmationResult;
    })
    .finally(() => {
      verifyContainer.removeChild(container);
    });
};

export const confirmFirebaseCode = async (code: string) => {
  const frebase = getFirebase();
  if (!frebase.confirmationResult || !window.confirmationResult) {
    return new Error("Failed to send verification code");
  }
  log("verify_sms_1", { message: "sended" });
  return (
    frebase.confirmationResult?.confirm(code) ||
    window.confirmationResult?.confirm(code)
  );
};
