// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import i18n from "../../locale/i18n";

const { t } = i18n;
export const list: StepType[] = [
  {
    id: 1,
    status: "",
    text: t("components.steps.Connect"),
    pending: false,
    taskId: "",
    _index: 0,
  },
  {
    id: 2,
    status: "",
    text: t("components.steps.Verify"),
    pending: false,
    taskId: "",
    _index: 1,
  },
  {
    id: 3,
    status: "",
    text: t("components.steps.Receive"),
    pending: false,
    taskId: "",
    _index: 2,
  },
];

export interface StepType {
  id: number;
  status: string;
  text: string;
  pending: boolean;
  taskId: string;
  [key: string]: any;
}

export interface CountryType {
  code: string;
  name: string;
  emoji: string;
  callCode: string;
}
export interface Region {
  code: string;
  callCode: string[];
  alpha2Code: string;
}

export interface RealRegion {
  code: string;
  callCode: string[];
  alpha2Code: string;
}

export interface RegionInfo {
  code: string;
  callCode: string[];
  alpha2Code: string;
  ip: string;
  regions: Region[];
  realRegion: RealRegion;
  lang: string;
}
