// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import step3 from "../../assets/celoimages/step3.svg";
import { Step } from "./components";
import { observer } from "mobx-react";
import { default as store } from "./mobx/CeloSteps";
import { useTranslation } from "react-i18next";
import { CELO_TASK_TYPES } from "../../config/celoTasks";
import { log } from "../../utils/log";

const CeloAirdrop = () => {
  const { t } = useTranslation();

  const finishedTime = () => {
    if (
      !store.stepList?.find(
        (step) => step.subType === CELO_TASK_TYPES.transferType
      )?.finishTs
    )
      return "";
    const date = new Date(
      store.stepList?.find(
        (step) => step.subType === CELO_TASK_TYPES.transferType
      )?.finishTs * 1000
    );
    return date.toLocaleDateString(navigator.language).replaceAll("/", ".");
  };
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 pc:bg-promoGradient">
      <div
        className="fixed overflow-y-auto top-0 left-0 right-0 bottom-0 flex flex-col items-center
       mx-auto px-3
       pc:max-w-404 pc:mt-24px bg-white-fff pc:border-4 pc:mb-80px
       "
      >
        <div className="font-semibold mt-32">
          {t("views.celoairdrop.title", {
            coin_symbol: store.accountInfo?.timeStock?.symbol,
          })}
        </div>
        <Step step={3}></Step>
        <img src={step3} className="mt-5" alt="step 3" />
        <h3 className="font-semibold text-20 mt-24 text-center">
          {t("views.celoSuccess.title1", {
            coin_symbol: store.accountInfo?.timeStock?.symbol,
          })}
        </h3>
        <div className="text-14-22 flex text-center mt-1">
          {store.stepList?.find((step) => step.index === 2)?.status ===
          "checked"
            ? t("views.celoSuccess.checkedContent", {
                finishedTime: finishedTime(),
                coin_symbol: store.accountInfo?.timeStock?.symbol,
              })
            : t("views.celoSuccess.content", {
                coin_amount: `${store?.accountInfo?.timeStock?.amount} ${store.accountInfo?.timeStock?.symbol}`,
              })}
        </div>
        <div className="flex items-center mt-10">
          <i className="icon-wallet_white"></i>&nbsp;&nbsp;
          <span>{`${store.accountInfo.account.slice(
            0,
            5
          )}...${store.accountInfo.account.slice(-8)}`}</span>
        </div>
        {store.stepList?.find(
          (step) => step.subType === CELO_TASK_TYPES.transferType
        )?.params?.visit_link ? (
          <div className=" pt-3 w-full flex flex-col justify-end flex-1 mb-16px text-center">
            <div className="border-t-2">
              <div className="py-3">
                {
                  store.stepList?.find(
                    (step) => step.subType === CELO_TASK_TYPES.transferType
                  )?.params?.visit_content
                }
              </div>
              <button
                className="border border-black-0d0d0d rounded-full px-5 h-32px text-14-16"
                onClick={() => {
                  log("visit_click", { message: "clicked" });
                  window.open(
                    store.stepList?.find(
                      (step) => step.subType === CELO_TASK_TYPES.transferType
                    )?.params?.visit_link
                  );
                }}
              >
                {
                  store.stepList?.find(
                    (step) => step.subType === CELO_TASK_TYPES.transferType
                  )?.params?.visit_title
                }
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default observer(CeloAirdrop);
