export const UPCOMING = "Upcoming";
export const ONGOING = "Ongoing";
export const ENDED = "Ended";
export const TASK_STATUS = {
  CHECKED: "checked",
  UNCHECKED: "un-checked",
  VERIFING: "verifing",
  LOADING: "loading",
  NULL: ""
};

export const WINNER_STATUS = {
  ISWINNER: "isWinner",
  ISNOTWINNER: "isNotWinner",
  ISNOTJOIN: "isNotJoin",
}