import success from "../../../assets/icons/success.svg";
import fail from "../../../assets/icons/fail.svg";
import loading from "../../../assets/icons/loading.svg";
import type { Task, UserStatus } from "../Type";
import { useState } from "react";
import { postAccomplish } from "../../../models/task";
import { TASK_STATUS } from "../Constants";
import Cookies from "js-cookie";
import { isOperaMiniBrowser } from "../../../utils/platform";
import { useTranslation } from "react-i18next";

const FollowTwitter = (props: {
  task: Task;
  userStatus: UserStatus;
  complateTask: Function;
  isConnectedInMini: boolean;
}) => {
  const { t } = useTranslation();
  const { task, complateTask, isConnectedInMini } = props;
  const [status, setStatus] = useState(
    task.status === TASK_STATUS.CHECKED
      ? TASK_STATUS.CHECKED
      : task.directVerify || isConnectedInMini
      ? TASK_STATUS.VERIFING
      : TASK_STATUS.NULL
  );
  const params: any = task.params;

  const handleClick = () => {
    if (!task.hasLink) return;
    let link = params["link"];
    if (isOperaMiniBrowser && params["mini_link"]) {
      link = params["mini_link"];
    }
    window.open(link);
    if (status === TASK_STATUS.CHECKED) return;
    setStatus(TASK_STATUS.VERIFING);
  };

  const handleVerify = async () => {
    setStatus(TASK_STATUS.LOADING);
    const teskRes = await postAccomplish({
      taskId: task.taskId,
    });
    if (teskRes?.data.status === TASK_STATUS.CHECKED) {
      setStatus(TASK_STATUS.CHECKED);
      complateTask(task?.taskId);
      Cookies.remove("twitter_name");
    } else {
      setStatus(TASK_STATUS.UNCHECKED);
      setTimeout(() => {
        setStatus(TASK_STATUS.VERIFING);
      }, 3000);
    }
  };

  return (
    <div className="mb-5 flex text-base">
      <div
        onClick={handleClick}
        className="flex-1"
        dangerouslySetInnerHTML={{ __html: params?.rich_text as string }}
      ></div>
      {status === TASK_STATUS.UNCHECKED && <img src={fail} alt="" />}
      {status === TASK_STATUS.CHECKED && <img src={success} alt="" />}
      {status === TASK_STATUS.LOADING && (
        <img src={loading} className="animate-spin" alt="" />
      )}
      {status === TASK_STATUS.VERIFING && (
        <button
          onClick={handleVerify}
          className="text-white-fff px-2 h-6 bg-green-0ed text-xs rounded hover:bg-green-00c"
        >
          {t("common.verify")}
        </button>
      )}
    </div>
  );
};

export default FollowTwitter;
