/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
const getQueryObj = (queryString = "") => {
  const obj:any = {}
  if (queryString) {
    const queryArray = queryString.split("&");
    queryArray.forEach(item => {
      if (item) {
        const arr = item.split("=");
        if (arr[0]) {
          obj[arr[0]] = arr?.[1] || "";
        }
      }
    });
  }
  return obj
}

const getQueriesFromSearch = (url: string) => {
  const obj:any = {}
  const queryString = url.split("?")?.[1] || "";
  if (queryString) {
    localStorage.setItem("queryString", queryString);
    const queryArray = queryString.split("&");
    queryArray.forEach(item => {
      const arr = item.split("=");
      obj[arr[0]] = arr?.[1] || "";
    });
  }
  return obj
};

export {
  getQueryObj,
  getQueriesFromSearch,
}
