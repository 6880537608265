// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import i18n, { ThirdPartyModule, InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import lang from "./lang";

i18n.use(initReactI18next as ThirdPartyModule).init({
  lng: window.lang,
  fallbackLng: "en",
  resources: lang,
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
} as InitOptions);

export default i18n;
