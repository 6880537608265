// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import money from "../../assets/celoimages/money.png";
import error from "../../assets/celoimages/error.svg";
import { default as store } from "./mobx/CeloSteps";
import { isOperaMiniBrowser, platform } from "../../utils/platform";

const SocialLinks = () => {
  const plat = platform();
  return (
    <div className="mt-10 text-sm">
      {plat === "h5" &&
        (store.detailInfo?.links?.discord ||
          store.detailInfo?.links?.website ||
          store.detailInfo?.links?.telegram ||
          store.detailInfo?.links?.twitter) && (
          <div className="text-center">Join us on</div>
        )}
      <div className="flex justify-around max-w-120">
        {store.detailInfo?.links?.website && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={store.detailInfo?.links?.website}
          >
            <div className="w-6 h-6 desktop bg-no-repeat bg-contain"></div>
          </a>
        )}
        {store.detailInfo?.links?.discord && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={store.detailInfo?.links?.discord}
          >
            <div className="w-6 h-6 discord bg-no-repeat bg-contain"></div>
          </a>
        )}
        {store.detailInfo?.links?.telegram && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={store.detailInfo?.links?.telegram}
          >
            <div className="w-6 h-6 telegram bg-no-repeat bg-contain"></div>
          </a>
        )}
        {store.detailInfo?.links?.twitter && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={store.detailInfo?.links?.twitter}
          >
            <div className="w-6 h-6 twitter bg-no-repeat bg-contain"></div>
          </a>
        )}
      </div>
    </div>
  );
};

const Landing = observer(() => {
  const { id, subId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const plat = platform();
  const [showError, setShowError] = useState(false);
  const handleJump = () => {
    if (new Date().getTime() > store?.accountInfo?.timeStock?.endTs * 1000) {
      setShowError(true);
      return;
    }
    const targetUrl = window.location.href.replace("/landing", "");
    window.location.href = isOperaMiniBrowser
      ? `intent://crypto_wallet?url=${encodeURIComponent(
          targetUrl
        )}#Intent;scheme=opera-mini;package=com.opera.mini.native;end`
      : targetUrl;
  };

  store.setActivityId(id as string);

  useEffect(() => {
    store.setStepList({
      id: id as string,
      slot: subId as string,
      signature: query.get("signature") as string,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="p-4">
      <img
        src={
          plat === "h5"
            ? store.detailInfo?.bannerBackgroundH5
            : store.detailInfo?.bannerBackground
        }
        alt=""
      />
      <div className="pc:max-w-624 my-0 mx-auto">
        <div className="flex justify-center flex-col items-center">
          <img src={money} className="w-12 h-12 mt-9 mb-5" alt="" />
          <div className="text-sm font-semibold">Remaining prizes</div>
          <div className="text-red-ff55 mt-2 mb-7">
            <span className="text-xl font-semibold">
              {store.accountInfo?.timeStock?.remaining *
                store.accountInfo?.timeStock?.amount}{" "}
              /{" "}
              {store.accountInfo?.timeStock?.total *
                store.accountInfo?.timeStock?.amount}
            </span>{" "}
            ${store.accountInfo?.timeStock?.symbol}
          </div>
          <button
            disabled={
              store.accountInfo?.timeStock?.remaining *
                store.accountInfo?.timeStock?.amount ===
              0
            }
            onClick={
              store.accountInfo?.timeStock?.remaining *
                store.accountInfo?.timeStock?.amount ===
              0
                ? () => {}
                : handleJump
            }
            className={`w-80 h-14 rounded-12xl text-white-fff font-semibold ${
              !(
                store.accountInfo?.timeStock?.remaining *
                store.accountInfo?.timeStock?.amount
              )
                ? "bg-blue-1241"
                : "bg-26308"
            }`}
          >
            Claim
          </button>
          {showError && (
            <div className="text-red-f35 mt-3">The event has expired.</div>
          )}
          {store.accountInfo?.timeStock?.remaining *
          store.accountInfo?.timeStock?.amount ? (
            <div className="mt-4 mb-10">
              👆🏻 Click here to claim your{" "}
              {`${store.accountInfo?.timeStock?.amount} $${store.accountInfo?.timeStock?.symbol}`}
              👆🏻{" "}
            </div>
          ) : (
            <div className="flex mt-5 mb-10">
              <img src={error} alt="" /> The list of today’s winners is full
            </div>
          )}

          <div className="border-t border-dotted border-white-d8d w-full"></div>
          {plat === "h5" && <SocialLinks />}
        </div>
        {new Date().getTime() > store?.accountInfo?.timeStock?.endTs * 1000 ||
        store?.accountInfo?.timeStock?.total === 0 ? (
          ""
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: store.detailInfo?.content as string,
            }}
          ></div>
        )}

        {plat === "pc" && <SocialLinks />}
      </div>
    </div>
  );
});

export default Landing;
