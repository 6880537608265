import "./Input.css";

const TaskCard = (props: {
  inputTitle: string;
  fieldName: string;
  changeInput: Function;
}) => {
  const { inputTitle, fieldName, changeInput } = props;

  return (
    <div className="flex-1">
      <div className="mb-2 text-base">{inputTitle}</div>
      <div className="h-12 px-4 box-border border rounded-lg border-white-e4e">
        <input
          className="w-full h-full"
          onInput={(e: any) => {
            changeInput(fieldName, e.target.value.trim());
          }}
        />
      </div>
    </div>
  );
};

export default TaskCard;
