// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.
import { useEffect, useState, useRef } from "react";

const InputItem = (props: {
  inputVal: number;
  setInput: (item: string[]) => void;
  smsPending: boolean;
  index: number;
}) => {
  const [focus, setFocus] = useState(false);
  const { inputVal, setInput, smsPending,index } = props;
  return (
    <div className={`border border-gray-00054 rounded-md w-24px py-1 mx-1 box-border flex justify-center items-center
    ${focus?"bg-black-0d0d0d text-white-fff":""}
    `}>
      <input
        className={`text-20 w-3 text-center bg-transparent`}
        type="number"
        maxLength={1}
        onChange={(e: any) => {
          setInput(`${e.target.value}`.slice(0, 6).split(""));
        }}
        value={inputVal}
        readOnly={smsPending}
        autoFocus={index===0}
        onFocus={()=>{
          setFocus(true);
        }}
        onBlur={()=>{
          setFocus(false);
        }}
      />
    </div>
  );
};
const SmsInput = (props: {
  callSms: (sms: number[]) => void;
  loading: boolean;
  getSms: (sms: number[]) => void;
}) => {
  const { callSms, loading, getSms } = props;
  const [sms, setsms] = useState<any[]>(["", "", "", "", "", ""]);
  const [autoCount, setCount] = useState(1);
  const inputRef = useRef<any>();

  useEffect(() => {
    document.addEventListener("keyup", tabForward);

    const _sms = sms.filter((val) => val !== "");
    getSms(sms);
    if (_sms.length === 6 && autoCount === 1) {
      callSms(sms);
      setCount(2);
    }
    return () => {
      document.removeEventListener("keyup", tabForward);
    };
  }, [sms, autoCount, callSms, getSms]);

  const tabForward = (e: any) => {
    const target = e.target;
    if (target.value.length === 1) {
      const elements = document
        .getElementsByClassName("inputCon")[0]
        .getElementsByTagName("input");
      for (let i = 0; i < elements.length; i++) {
        if (elements[i] === target) {
          if (elements[i + 1]) {
            elements[i + 1].focus();
          }
          return;
        }
      }
    }
    if (target.value.length === 0) {
      const elements = document
        .getElementsByClassName("inputCon")[0]
        .getElementsByTagName("input");
      for (let i = 0; i < elements.length; i++) {
        if (elements[i] === target) {
          if (elements[i - 1]) {
            elements[i - 1].focus();
          }
          return;
        }
      }
    }
  };
  const inputs = sms.map((val, index) => (
    <InputItem
      inputVal={val}
      key={index}
      smsPending={loading}
      index={index}
      setInput={(items: string[]) => {
        let _sms = sms.slice(0);
        items.forEach((val, i) => {
          if (i === 0) {
            _sms[index] = val;
          } else if (index < 5) {
            _sms[++index] = val;
          }
        });
        if (items.length === 0) {
          _sms[index] = "";
        }
        setsms(_sms);
      }}
    ></InputItem>
  ));
  return (
    <div className="flex my-3 inputCon" ref={inputRef}>
      {inputs}
    </div>
  );
};

export default SmsInput;
