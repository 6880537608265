import cb from "../../../assets/common/cb.png";
import opera from "../../../assets/common/opera.png";
import mac from "../../../assets/icons/mac.svg";
import windows from "../../../assets/icons/windows.svg";
import { isInAndroid, platform } from "../../../utils/platform";
import { useTranslation } from "react-i18next";
import { Links } from "../Type";

const Download = (props: { links: Links }) => {
  const { links } = props;
  const { t } = useTranslation();
  const plat = platform();
  const defaultLinks = {
    ofa: "https://opr.as/t71l",
    mini: "https://opr.as/92qb",
    cbd: "https://www.opera.com/crypto/next&referrer=af_tran?utm_source=savi&utm_id=airdorp",
    ofi: "https://apps.apple.com/app/opera/id1565519310#utm_source=savi&utm_campaign=airdrop&utm_id=airdrop",
  };
  const isMac = navigator.userAgent.toLowerCase().indexOf("mac") > -1;
  return (
    <div className="pc:max-w-458 pc:min-w-312 pc:w-0.265 shadow-00102 rounded-xl overflow-hidden pc:ml-78 pc:-mt-7 h5:mt-7">
      <div className="py-8 bg-white-fff flex items-center flex-col justify-center">
        <img src={plat === "h5" ? opera : cb} className="w-20 h-20" alt="" />
        <div className=" text-xl font-bold ">Opera</div>
        <div className="border-t border-black w-5/6 opacity-50 my-10"></div>
        <div className=" text-sm w-5/6 text-center mb-10">
          {plat === "h5"
            ? t("common.installOperaBrowser")
            : t("common.installOperaCryptoBrowser")}
        </div>
        {plat === "h5" ? (
          isInAndroid ? (
            <div className="w-80% max-w-408">
              <a href={links?.ofa || defaultLinks.ofa}>
                <div className="bg-green-0ed text-white-fff h-15 flex items-center justify-center font-bold rounded-lg mb-3">
                  Download Opera for Android
                </div>
              </a>
              <a href={links?.mini || defaultLinks.mini}>
                <div className="bg-green-0ed text-white-fff h-15 flex items-center justify-center font-bold rounded-lg">
                  Download Opera MINI
                </div>
              </a>
            </div>
          ) : (
            <div className="h-12 w-80% max-w-408">
              <a href={links?.ofi || defaultLinks.ofi}>
                <div className="bg-green-0ed text-white-fff h-15 flex items-center justify-center font-bold rounded-lg">
                  Download Opera for iOS
                </div>
              </a>
            </div>
          )
        ) : (
          <a
            href={links?.cbd || defaultLinks.cbd}
            className="w-90% h-15 cursor-pointer bg-green-0ed flex justify-center items-center rounded-lg hover:bg-green-00c"
          >
            <img src={isMac ? mac : windows} className="mr-3" alt="" />
            <div className="text-base ">
              {t("common.downloadCryptoBrowser")}
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

export default Download;
