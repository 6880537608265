import { useState } from "react";
import "./Input.css";
import search from "../../../../assets/icons/search.svg";
import type { Country } from "../../Type";

const TaskCard = (props: {
  inputTitle: string;
  fieldName: string;
  placeholder: string;
  countryList: Array<Country>;
  changeCountry: Function;
}) => {
  const { placeholder, fieldName, countryList, changeCountry, inputTitle } =
    props;
  const [searchResult, setSearchResult] = useState<Country[]>();
  const [selectCountry, setSelectCountry] = useState("");
  const [selectCountryEmoji, setSelectCountryEmoji] = useState("");
  const [showSelect, setShowSelect] = useState(false);

  const cancleInput = (e: any) => {
    setSelectCountry(e.target.value);
    setSelectCountryEmoji("");
    const searchInfo = e.target.value.toUpperCase();
    if (e.target.value.length === 0) {
      return;
    }
    const result = [];
    for (let i = 0; i < countryList.length; i++) {
      const matchCountry: Country = countryList[i];
      const countryName = matchCountry.name;
      if (countryName.toUpperCase().indexOf(searchInfo) > -1) {
        result.push(countryList[i]);
      }
    }
    setSearchResult(result);
  };
  const changeCurCountry = (country: Country) => {
    setSelectCountry(country.name);
    setSelectCountryEmoji(country.emoji);
    changeCountry(fieldName, country.code);
    setShowSelect(false);
  };
  return (
    <div className="relative">
      <div className="mb-2 text-base">{inputTitle}</div>
      <div className="h-12 px-4 box-border border rounded-lg border-white-e4e flex items-center">
        {selectCountryEmoji ? (
          <span className="mr-3 text-2xl">{selectCountryEmoji}</span>
        ) : (
          <img src={search} className="mr-2" alt="search"/>
        )}
        <input
          className="w-full h-full flex-1"
          value={selectCountry}
          placeholder={placeholder}
          onInput={cancleInput}
          onFocus={() => {
            setShowSelect(true);
          }}
        />
      </div>
      {showSelect && (
        <div className="w-full max-h-80 py-5 bg-white-fff border border-white-e5e rounded-lg borderShadow overflow-auto absolute z-50">
          {searchResult && (
            <div className="w-full">
              <div className="mb-5 px-5 text-xs text-black-50">
                You may want to choose
              </div>
              {searchResult &&
                searchResult.map((item: Country, index: Number) => {
                  return (
                    <div
                      key={index + "111"}
                      className="h-11 px-5 text-sm hover:bg-black-6 flex items-center cursor-pointer"
                      onClick={() => {
                        changeCurCountry(item);
                      }}
                    >
                      <span className="mr-3 text-2xl">{item.emoji}</span>
                      {item.name}
                    </div>
                  );
                })}
            </div>
          )}
          <div>
            <div className="mb-2 mt-6 px-5 text-xs text-black-50">
              Select Country/Region
            </div>
            {countryList.map((item: Country, index: Number) => {
              return (
                <div
                  key={index + "222"}
                  onClick={() => {
                    changeCurCountry(item);
                  }}
                  className="h-11 px-5 text-sm hover:bg-black-6 flex items-center cursor-pointer"
                >
                  <span className="mr-3 text-2xl">{item.emoji}</span>
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskCard;
