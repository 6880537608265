import "./Input.css";
import calendar from "../../../../assets/icons/calendar.svg";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";

const TaskCard = (props: {
  inputTitle: string;
  fieldName: string;
  placeholder: string;
  changeInput: Function;
}) => {
  const { placeholder, fieldName, inputTitle, changeInput } = props;

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    changeInput(fieldName, dateString);
  };
  return (
    <div className="relative  flex-1">
      <div className="mb-2 text-base">{inputTitle}</div>
      <div className="h-12 px-4 box-border border rounded-lg border-white-e4e flex items-center cursor-pointer">
        <img src={calendar} className="mr-2" alt="calendar"/>
        <input className="w-full h-full flex-1 readonly" />
        <div className="w-full absolute left-0">
          <DatePicker
            onChange={onChange}
            bordered={false}
            inputReadOnly
            placeholder={placeholder}
            className="w-full cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
