import time from "../../../assets/icons/time.svg";
import lock from "../../../assets/icons/lock.svg";
import done from "../../../assets/icons/done.svg";
import happy from "../../../assets/icons/happy.svg";
import notjoin from "../../../assets/icons/notjoin.svg";
import nothappy from "../../../assets/icons/nothappy.svg";
import error from "../../../assets/icons/error.svg";
import "./TaskCard.css";
import { useEffect, useMemo, useState } from "react";
import {
  UPCOMING,
  ONGOING,
  ENDED,
  TASK_STATUS,
  WINNER_STATUS,
} from "../Constants";
import type { Task, UserStatus, Winner } from "../Type";
import Connect from "./Connect";
import FollowTwitter from "./FollowTwitter";
import ReTwitter from "./ReTwitter";
import QuoteTwitter from "./QuoteTwitter";
import JoinDiscord from "./JoinDiscord";
import JoinTelegram from "./JoinTelegram";
import CommonTask from "./CommonTask";
import Swap from "./Swap";
import VerifiedForm from "./VerifiedForm";
import { formatDateWithYear } from "../../../utils/formatDate";
import useCountdown from "./CountDown";
import moment from "moment";
import { getWiners } from "../../../models/task";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isOperaMiniBrowser } from "../../../utils/platform";

const SwitchTask = (props: {
  task: Task;
  complateTask: Function;
  changeStatus: Function;
  userStatus: UserStatus;
  startTimestamp: string;
  endTimestamp: string;
  isConnectedInMini: boolean;
}) => {
  const {
    task,
    complateTask,
    changeStatus,
    userStatus,
    startTimestamp,
    endTimestamp,
    isConnectedInMini,
  } = props;
  switch (task.subType) {
    case "connect":
      return task.isVisible ? (
        <Connect
          complateTask={complateTask}
          task={task}
          changeStatus={changeStatus}
        ></Connect>
      ) : null;
    case "follow_tweet":
      return task.isVisible ? (
        <FollowTwitter
          complateTask={complateTask}
          isConnectedInMini={isConnectedInMini}
          task={task}
          userStatus={userStatus}
        />
      ) : null;
    case "retweet":
      return task.isVisible ? (
        <ReTwitter
          complateTask={complateTask}
          isConnectedInMini={isConnectedInMini}
          task={task}
          userStatus={userStatus}
        />
      ) : null;
    case "quote_tweet":
      return task.isVisible ? (
        <QuoteTwitter
          complateTask={complateTask}
          isConnectedInMini={isConnectedInMini}
          task={task}
          userStatus={userStatus}
        />
      ) : null;
    case "join_discord":
      return task.isVisible ? (
        <JoinDiscord
          complateTask={complateTask}
          isConnectedInMini={isConnectedInMini}
          task={task}
          userStatus={userStatus}
        />
      ) : null;
    case "swap":
      return task.isVisible ? (
        <Swap
          complateTask={complateTask}
          task={task}
          userStatus={userStatus}
          startTimestamp={startTimestamp}
          endTimestamp={endTimestamp}
        />
      ) : null;
    case "join_telegram":
      return task.isVisible ? (
        <JoinTelegram
          complateTask={complateTask}
          isConnectedInMini={isConnectedInMini}
          task={task}
          userStatus={userStatus}
        />
      ) : null;
    case "common":
      return task.isVisible ? (
        <CommonTask
          complateTask={complateTask}
          isConnectedInMini={isConnectedInMini}
          task={task}
          userStatus={userStatus}
        />
      ) : null;
    default:
      return task.isVisible ? (
        <CommonTask
          complateTask={complateTask}
          isConnectedInMini={isConnectedInMini}
          task={task}
          userStatus={userStatus}
        />
      ) : null;
  }
};

const TaskCard = (props: {
  status: string;
  startTimestamp: string;
  endTimestamp: string;
  tasks: Task[];
  userStatus: UserStatus;
  getCampaignData: Function;
  needKyc: boolean;
  isRefpage: boolean;
}) => {
  const {
    status,
    tasks,
    endTimestamp,
    userStatus,
    getCampaignData,
    startTimestamp,
    needKyc,
    isRefpage,
  } = props;
  const { t } = useTranslation();
  const [winners, setWinners] = useState<Winner[]>();
  const [winnerStatus, setWinnerStatus] = useState(WINNER_STATUS.ISNOTJOIN);
  const preTasks = tasks?.filter((item) => !item.preTaskIds);
  const [isOpenTasks, setIsOpenTasks] = useState(false);
  const normalTasks = tasks?.filter((item) => !!item.preTaskIds);
  // const unCheckedTasks: string[] = [];
  const [unCheckedTasks, setUnCheckedTasks] = useState<string[]>();
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isShowVerify, setIsShowVerify] = useState(false);
  const [KYCStat, setKYCStat] = useState(Number);
  //if we no connect, we can't see other cards.
  const isConnectedInMini = isOperaMiniBrowser;
  useEffect(() => {
    let arr: string[] = [];
    tasks?.forEach((t) => {
      if (t.status !== TASK_STATUS.CHECKED) {
        arr.push(t.taskId);
      }
    });
    setUnCheckedTasks(arr);
  }, [tasks]);
  useEffect(() => {
    setKYCStat(userStatus?.kycInfo?.KYCStat);
  }, [userStatus]);
  useEffect(() => {
    document.body.style.overflow = isShowVerify ? "hidden" : "unset";
  }, [isShowVerify]);

  const complateTask = (taskId: string) => {
    const tempArr = unCheckedTasks?.filter((ut) => ut !== taskId);
    setUnCheckedTasks(tempArr);
  };
  const deadline = useMemo(
    () => moment(Number(endTimestamp) * 1000).format("YYYY-MM-DD HH:mm:ss"),
    [endTimestamp]
  );
  const { day, hour, minute, second } = useCountdown({
    deadline,
  });
  useEffect(() => {
    if (
      status === ONGOING &&
      day === 0 &&
      hour === 0 &&
      minute === 0 &&
      second === 0
    ) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [second]);
  const params = useParams();

  useEffect(() => {
    if (userStatus?.winnerCount > 0) {
      getWiners({ campaignId: params.id }).then((res) => {
        console.log(res);
        setWinners(res?.data);
        const isMatchWinner = res?.data.find(
          (winner: any) =>
            winner.walletAddr === userStatus.walletAddr && userStatus.walletAddr
        );
        console.log("isMatchWinner:", isMatchWinner);
        if (userStatus?.isRegisted) {
          setWinnerStatus(
            isMatchWinner ? WINNER_STATUS.ISWINNER : WINNER_STATUS.ISNOTWINNER
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userStatus]);

  const handleJoinIn = () => {
    if ((unCheckedTasks as string[])?.length > 0) {
      console.log(unCheckedTasks);
      setIsShowAlert(true);
    } else {
      setIsShowAlert(false);
      getCampaignData();
    }
  };

  const changeStatus = (status: boolean) => {
    getCampaignData();
    setIsOpenTasks(status);
  };

  return (
    <div className="pc:max-w-458 pc:min-w-312 pc:w-0.265 shadow-00102 rounded-xl overflow-hidden pc:ml-78 pc:-mt-7 h5:mt-7">
      {status === ONGOING && (unCheckedTasks as string[])?.length > 0 && (
        <div className="h-158 bg-11043 text-white-fcf flex items-center flex-col justify-center">
          <div className="flex items-center text-base">
            <img className="mr-1 -mt-n2" src={time} alt="" />
            <span className="ml-n2">{t("common.endsOn")}</span>
          </div>
          {/* <div className="text-4xl font-bold mt-4 flex font-DIN-Condensed">
            {formatDateWithYear(props.startTimestamp)}
          </div> */}
          <div className="text-4xl font-bold mt-4 flex font-DIN-Condensed">
            <div className="text-center">
              <div>{day}</div>
              <div className="text-xs text-white-fcf font-normal opacity-50">
                D
              </div>
            </div>
            &nbsp;:&nbsp;
            <div className="text-center">
              <div>{hour}</div>
              <div className="text-xs text-white-fcf font-normal opacity-50">
                H
              </div>
            </div>
            &nbsp;:&nbsp;
            <div className="text-center">
              <div>{minute}</div>
              <div className="text-xs text-white-fcf font-normal opacity-50">
                M
              </div>
            </div>
            &nbsp;:&nbsp;
            <div className="text-center">
              <div>{second}</div>
              <div className="text-xs text-white-fcf font-normal opacity-50">
                S
              </div>
            </div>
          </div>
        </div>
      )}
      {((status === ONGOING && (unCheckedTasks as string[])?.length === 0) ||
        (status === ENDED &&
          userStatus?.winnerCount === 0 &&
          userStatus?.isRegisted)) && (
        <div className="h-158 bg-110435 text-white-fcf flex items-center flex-col justify-center">
          <img src={done} alt="" />
          <div className="font-bold text-xl mt-3">
            {t("common.youAreRegistered")}
          </div>
          <div className="text-sm opacity-60">
            {t("common.waitingForTheDraw")}
          </div>
        </div>
      )}
      {status === ENDED &&
        userStatus?.winnerCount === 0 &&
        !userStatus?.isRegisted && (
          <div className="h-158 bg-white-fff flex items-center flex-col justify-center">
            <img src={notjoin} alt="" />
            <div className="font-bold text-xl mt-3">Registration is closed</div>
            <div className="text-sm opacity-60">
              {t("common.followForMore")}
            </div>
            <div className="border-t border-black w-5/6 opacity-50 relative top-7"></div>
          </div>
        )}
      {status === ENDED &&
        userStatus?.winnerCount > 0 &&
        winnerStatus === WINNER_STATUS.ISNOTJOIN && (
          <div className="pt-7 bg-white-fff flex items-center flex-col justify-center">
            <img src={notjoin} alt="" />
            <div className="font-bold text-xl mt-3">
              {t("common.giveawayEnded")}
            </div>
            <div className="text-sm opacity-60">
              {t("common.followForMore")}
            </div>
            {!tasks?.find(
              (item) =>
                item.subType === "connect" &&
                item.status === TASK_STATUS.CHECKED
            ) && (
              <Connect
                onlyFunc={true}
                complateTask={complateTask}
                task={tasks?.[0]}
                changeStatus={changeStatus}
              />
            )}
            <div className="border-t border-black w-5/6 opacity-50 relative top-7"></div>
          </div>
        )}
      {status === ENDED &&
        userStatus?.winnerCount > 0 &&
        winnerStatus === WINNER_STATUS.ISNOTWINNER && (
          <div className="h-158 bg-white-fff flex items-center flex-col justify-center">
            <img src={nothappy} alt="" />
            <div className="font-bold text-xl mt-3">You were not selected</div>
            <div className="text-sm opacity-60">
              {t("common.followForMore")}
            </div>
            <div className="border-t border-black w-5/6 opacity-50 relative top-7"></div>
          </div>
        )}
      {status === ENDED &&
        userStatus?.winnerCount > 0 &&
        winnerStatus === WINNER_STATUS.ISWINNER && (
          <div className="h-56 bg-10237 text-white-fcf flex items-center flex-col justify-center">
            <img src={happy} alt="" />
            <div className="font-bold text-xl mt-3">
              {t("common.youAreWinner")}
            </div>
            {needKyc && (
              <div className="flex flex-col items-center">
                {KYCStat === 1 ? (
                  <div className="text-sm opacity-60">
                    Your wallet will receive {winners?.[0].amount}&nbsp;
                    {winners?.[0].unit}.
                  </div>
                ) : (
                  <div className="w-52 text-sm opacity-60 text-center">
                    Verify your identity to receive {winners?.[0].amount}&nbsp;
                    {winners?.[0].unit} in your wallet.
                  </div>
                )}
                {!KYCStat && (
                  <div
                    className="w-28 h-8 mt-4 bg-white-fff text-xs font-bold text-red-f33 rounded-12xl flex justify-center items-center cursor-pointer"
                    onClick={() => {
                      setIsShowVerify(true);
                    }}
                  >
                    {t("common.verify")}
                  </div>
                )}
                {KYCStat === 1 && (
                  <div className="mt-11 text-xs  font-bold flex items-center">
                    <span className="m-2 text-base">✅</span>{" "}
                    {t("common.verified")}
                  </div>
                )}
                {KYCStat === 2 && (
                  <div className="mt-6 text-xs font-bold flex items-center">
                    <span className="m-2 text-base">💡</span> Checking
                  </div>
                )}
                {KYCStat === 3 && (
                  <div
                    className="mt-6text-xs font-bold flex items-center underline"
                    onClick={() => {
                      setIsShowVerify(true);
                    }}
                  >
                    <span className="m-2 text-base">⚠️</span>
                    {t("common.verificationFailed")}
                  </div>
                )}
                {isShowVerify && (
                  <VerifiedForm
                    addr={userStatus?.walletAddr}
                    cancle={() => {
                      setIsShowVerify(false);
                    }}
                    getCampaignData={getCampaignData}
                  />
                )}
              </div>
            )}
          </div>
        )}
      {status === UPCOMING && (
        <div className="h-158 bg-11043 text-white-fcf flex items-center flex-col justify-center">
          <div className="flex items-center text-base">
            <img className="mr-1 -mt-n2" src={time} alt="" />
            <span className="ml-n2">Starts on</span>
          </div>
          <div className="text-4xl font-bold mt-4 flex font-DIN-Condensed">
            {formatDateWithYear(startTimestamp)}
          </div>
        </div>
      )}
      {(status === UPCOMING ||
        status === ONGOING ||
        (status === ENDED && userStatus?.winnerCount === 0)) && (
        <div className="px-6 py-10">
          <div className="text-2xl font-bold">{t("common.eventTasks")}</div>
          <div className="text-sm opacity-50 mb-10">
            {t("common.completeTheFollowingTasks")}
          </div>
          {/* PRE-TASKS */}
          {preTasks.map((task) => (
            <SwitchTask
              key={task.taskId}
              task={task}
              complateTask={complateTask}
              changeStatus={changeStatus}
              userStatus={userStatus}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              isConnectedInMini={isConnectedInMini}
            ></SwitchTask>
          ))}
          {preTasks?.[0].status === "checked" || isOpenTasks ? (
            <div>
              {normalTasks?.map((task) => (
                <SwitchTask
                  key={task.taskId}
                  task={task}
                  complateTask={complateTask}
                  changeStatus={changeStatus}
                  userStatus={userStatus}
                  startTimestamp={startTimestamp}
                  endTimestamp={endTimestamp}
                  isConnectedInMini={isConnectedInMini}
                ></SwitchTask>
              ))}
            </div>
          ) : normalTasks.length ? (
            <div className="relative h-234 bg-white-dbf rounded-lg mb-5 flex justify-center items-center flex-col">
              <img src={lock} alt="" />
              <div className="font-bold text-xl mt-4 mb-1 text-center">
                {t("common.unlock")}{" "}
                {tasks && tasks?.length ? normalTasks.length : ""}{" "}
                {t("common.moreEntries")}
              </div>
              <div className="max-w-180 text-xs text-center opacity-50">
                {t("common.completeTheAboveEntries")}
              </div>
            </div>
          ) : (
            ""
          )}
          {isShowAlert && (
            <div className="h-12 flex justify-center items-center rounded-lg bg-red-rgba234 text-red-f35 text-xs mb-4 border border-red-f35">
              <img className="mr-2" src={error} alt="" />
              {t("common.youNeedToComplete")}
            </div>
          )}
          {(status === ONGOING && (unCheckedTasks as string[])?.length === 0) ||
          (status === ENDED &&
            userStatus?.winnerCount === 0 &&
            userStatus?.isRegisted) ||
          isRefpage ? (
            ""
          ) : (
            <button
              onClick={
                status === UPCOMING || status === ENDED || (unCheckedTasks as string[])?.length !== 0
                  ? () => {}
                  : handleJoinIn
              }
              className={`w-full h-15 text-white-fff text-lg rounded-lg font-bold ${
                status === UPCOMING || status === ENDED || (unCheckedTasks as string[])?.length !== 0
                  ? "bg-white-d8d"
                  : "bg-green-0ed hover:bg-green-00c"
              }`}
            >
              {t("common.joinIn")}
            </button>
          )}
        </div>
      )}
      {status === ENDED && userStatus?.winnerCount > 0 && (
        <div className="px-6 py-10">
          <div className="font-bold">
            {t("common.winnersList")}
            <span className="text-green-0ed">
              （{userStatus?.winnerCount}）
            </span>
          </div>
          <div className="mt-5 max-h-96 overflow-y-scroll">
            {(winners as Winner[])?.map((winner, idx) => (
              <div
                key={idx}
                className={`flex justify-between text-sm mb-4 mr-3 ${
                  winner.walletAddr === userStatus.walletAddr
                    ? "text-green-0ed"
                    : ""
                }`}
              >
                <span
                  className={`${
                    winner.walletAddr === userStatus.walletAddr
                      ? ""
                      : "opacity-60"
                  }`}
                >
                  {idx + 1}.&nbsp;
                  {winner?.walletAddr.length > 10
                    ? `${winner?.walletAddr.slice(
                        0,
                        6
                      )}...${winner?.walletAddr.slice(-4)}`
                    : winner?.walletAddr}
                </span>
                {"winner.amount" ? "+" : ""} {winner.amount} {winner.unit}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TaskCard;
