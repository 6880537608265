// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

declare global {
  interface Window {
    ethereum: any;
    addEventListener<K extends keyof WindowEventMap>(
      type: K,
      listener: (this: Window, ev: WindowEventMap[K]) => any,
      options?: boolean | AddEventListenerOptions
    ): void;
    recaptchaVerifier:any; // google recap
    recaptchaWidgetId:any; // google recap
    confirmationResult:any; // google recap
    gtag: any;
  }
}

export const isConnected = async () => {
  return window.ethereum.isConnected();
};

export const request = async (method: string, ...params: any) => {
  return window.ethereum.request({ method: method, params: params });
};
