/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
export const platform = () => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  if (screenWidth <= 1080) {
    return "h5";
  } else {
    return "pc";
  }
};

export const UA = navigator.userAgent;

export const isOperaBrowser = UA.indexOf("OPR") > -1 || UA.indexOf("OPT") > -1;

export const isOperaMiniBrowser = isOperaBrowser && UA.indexOf("wv") > -1;

const IOS = "iOS";
const ANDROID = "Android";

const judgeMobileClient = () => {
  let client = "";
  if (/(iPhone|iPad|iPod|iOS)/i.test(UA)) {
    client = IOS;
  } else {
    client = ANDROID;
  }
  return client;
};

export const isInIOS = judgeMobileClient() === IOS;
export const isInAndroid = judgeMobileClient() === ANDROID;
