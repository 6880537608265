// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import step2 from "../../assets/celoimages/step2.svg";
import { Step, BottomSheet, CountryList } from "./components";
import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CountryType, RegionInfo } from "./stepList";
import { createBot } from "../../utils/firbasePhone";
import { getClentInfo, getCountries, presend } from "../../api/airdropCeloApi";
import { observer } from "mobx-react";
import { default as store } from "./mobx/CeloSteps";
import { getErrorMsg } from "../../config/celoErrCode";
import { useTranslation } from "react-i18next";
import { CELO_TASK_TYPES } from "../../config/celoTasks";

const CeloAirdrop = () => {
  const [countryList, setcountryList] = useState<CountryType[]>([]);
  const [regioInfo, setRegioInfo] = useState<RegionInfo>();
  const [loading, setLoading] = useState(false);
  const [callCodePreLoading, setCallCodePreLoading] = useState(false);
  const [focus, setFocus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const bottomSheetRef: any = useRef<HTMLDivElement | null>();
  const { t } = useTranslation();
  const ruoteParams = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  
  const selectCountry = (country: any) => {
    store.setAccountInfo("callCode", country.callCode);
    bottomSheetRef?.current.closeBottomSheet();
  };
  const navigate = useNavigate();

  useEffect(() => {
    setCallCodePreLoading(true);
    getClentInfo(
      store.stepList.find((val) =>
        val?.subType?.includes(CELO_TASK_TYPES.firebaseType)
      )?.params?.allow_regions as string
    )
      .then((res) => {
        const {
          regions: [region],
        } = res;
        setRegioInfo(res);
        store.setAccountInfo("callCode", region?.callCode[0]);
      })
      .finally(() => {
        setCallCodePreLoading(false);
      });
    getCountries(
      store.stepList.find((val) =>
        val?.subType?.includes(CELO_TASK_TYPES.firebaseType)
      )?.params?.allow_regions as string
    ).then((res) => {
      setcountryList(res);
    });
  }, []);

  const checkPhone = async () => {
    if (loading || !store.accountInfo.phone) return;
    setLoading(true);
    // validate 1 : ip / country
    const { realRegion, regions } = regioInfo as RegionInfo;
    if (!regions.find((r) => r.alpha2Code === realRegion.alpha2Code)) {
      setErrMsg("Sorry, you are not eligible to join this event.");
      setLoading(false);
      return;
    }
    // validate 2 : phone
    const res = await presend({
      campaign_id: store.accountInfo.activityId,
      phone_number: store.accountInfo.callCode + store.accountInfo.phone,
    });
    // Error block
    if (res?.status === 412) {
      // VALIDATE_ERROR
      setErrMsg("Sorry, you are not eligible to join this event.");
      setLoading(false);
      return;
    } else if (res?.status === 421) {
      // TOO_MANY_REQ
      setErrMsg("Too many requests.");
      setLoading(false);
      return;
    }
    createBot(
      "input-celo-phone-container",
      store.accountInfo.callCode + store.accountInfo.phone.trim()
    )
      .then((response) => {
        setLoading(false);
        navigate(`/celo/step2/smsverify/${ruoteParams.id}/${
          ruoteParams.subId
        }/?signature=${query.get("signature")}`, {
          replace: false,
        });
      })
      .catch((err) => {
        console.warn(err);
        setErrMsg(getErrorMsg(err.message));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 pc:bg-promoGradient">
      <div
        className="overflow-y-auto
      fixed top-0 left-0 right-0 bottom-0 flex flex-col items-center 
     input-celo-phone-container
      m-auto px-3 pc:max-w-404 pc:mt-24px bg-white-fff pc:border-4 pc:mb-80px"
      >
        <div className="font-semibold text-16 mt-32">
          {t("views.celoairdrop.title", {
            coin_symbol: store.accountInfo?.timeStock?.symbol,
          })}
        </div>
        <Step step={2}></Step>
        <img src={step2} className="mt-5" alt="step 3" />
        <h3 className="text-16 mt-24 text-center">
          {t("views.inputPhone.holderTips")}
        </h3>
        <div
          className={`flex  w-full mt-3.5 bg-gray-00008 h-56px box-border ${
            focus ? "pb-8px" : "pb-16px"
          } px-2 rounded-md relative text-12`}
        >
          {callCodePreLoading ? (
            <span className="animate-spin flex justify-end items-center">
              <i className="icon-loading"></i>
            </span>
          ) : (
            <div
              className={`h-24px flex items-center ${
                focus ? "mt-23px" : "mt-16px"
              }`}
            >
              <span
                onClick={() => {
                  bottomSheetRef?.current.showBottomSheet();
                }}
                className="border-r border-gray-00008 text-16"
              >
                {store.accountInfo.callCode || countryList[0]?.callCode}
                &nbsp;&nbsp;
              </span>
              &nbsp;&nbsp;
              <input
                className="bg-transparent text-16 w-full"
                type="number"
                onChange={(e: any) => {
                  store.setAccountInfo("phone", e.target.value);
                  setErrMsg("");
                }}
                value={store.accountInfo.phone}
                placeholder={t("views.inputPhone.holderText") as string}
                readOnly={loading}
                onFocus={() => {
                  setFocus(true);
                }}
                onBlur={() => {
                  setFocus(false);
                }}
              />
              {focus ? (
                <span className="absolute top-7 left-3 text-gray-000038">
                  {t("views.inputPhone.holderText")}
                </span>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {store.accountInfo.phone ? (
          <div className="text-red-D50000 text-12 pl-2 mt-1 w-full">
            {errMsg}
          </div>
        ) : (
          <></>
        )}
        <div className="text-12 flex text-left mt-3 text-gray-00006">
          {t("views.inputPhone.title")}
        </div>
        <div className="w-full pt-3 flex flex-col justify-end flex-1 mb-16px">
          <button
            className={`h-48px rounded-full text-14-16
      text-white-fff w-full
      ${
        !store.accountInfo.phone
          ? "bg-gray-f0f1f2 text-gray-000038"
          : "bg-black-100 text-white-fff"
      }
      pc:max-w-624`}
            disabled={loading || !store.accountInfo.phone}
            onClick={checkPhone}
          >
            {!loading ? (
              <>{t("views.inputPhone.btnText")}</>
            ) : (
              <i className="icon-loading animate-spin"></i>
            )}
          </button>
        </div>
        <BottomSheet ref={bottomSheetRef}>
          <CountryList
            selectCountry={selectCountry}
            countryList={countryList}
          ></CountryList>
        </BottomSheet>
      </div>
    </div>
  );
};

export default observer(CeloAirdrop);
