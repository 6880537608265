import React from 'react';
import { ActiveData } from "../Interface";
import { formatDateWithMonthtoMinute } from "../../../utils/formatDate";
import { useTranslation } from 'react-i18next';

const ActiveItem = (props: {
  item: ActiveData,
}) => {
  const { t } = useTranslation();
  const { item } = props;
  const time = new Date().getTime();
  const status =
    time > Number(item.endTimestamp) * 1000 ? t("common.ended") :
      time < Number(item.startTimestamp) * 1000 ? t("common.upcoming") : t("common.ongoing");
  const getTiemString = () => {
    return status === "Upcoming" ? `Start time ${formatDateWithMonthtoMinute(item.startTimestamp)}`
      : status === "Ended" ? `${formatDateWithMonthtoMinute(item.startTimestamp)}—${formatDateWithMonthtoMinute(item.endTimestamp)}`
        : `Expire time ${formatDateWithMonthtoMinute(item.endTimestamp)}`;
  };
  return (
    <div className="bg-gray-f6f dark:bg-black-1e2 rounded-lg px-3 mr-2 mb-2 h5:flex-shrink-0  pc:min-w-320 pc:flex-grow pc:w-80">
      <div className='px-1 py-4 flex items-center border-b border-gray-d4d-30 dark:border-gray-354-30'>
        <img className='block w-8 h-8 rounded-2xl box-border border border-gray-b0b dark:border-gray-517 p-px flex-shrink-0 flex-grow-0' src={item?.projectLogo} alt="" />
        <div className='flex-grow ml-3 text-14-16'>{item?.projectName}</div>
      </div>
      <div className='px-1 py-4 flex items-center dark:border-gray-354-30 border-dashed'>
        <div className='flex-grow flex-shrink'>
          <div className='text-16 font-bold'>{item?.amount}</div>
          <div className='mt-2 text-14-16 text-gray-607 dark:text-gray-7d9'>Giveaway to {item?.winners} winners</div>
        </div>
        <a className=" ml-4" target="_blank" rel="noreferrer" href={item.link || `/airdrop/${item.campaignId}`}>
          <button className={`flex-grow-0 flex-shrink-0 h-8 pt-0.5 w-24 rounded-2xl cursor-pointer text-xs font-bold ${status === 'Ended' ? 'bg-gray-e7e dark:bg-black-354 text-black-80 dark:text-white-80' : 'bg-green-0cd dark:bg-green-0ed dark:text-black-80 text-white-fff'}`}>{item?.btnText}</button>
        </a>
      </div>
      <div className='w-full h-px bg-repeat-x bg-dashed'></div>
      <div className='px-1 py-2.5 flex justify-between text-xs'>
        <div className={`capitalize font-medium ${status === 'Ended' ? 'text-red-e45 dark:text-red-ff5' : status === 'Upcoming' ? 'text-yellow-ffc dark:text-yellow-01f' : 'text-green-00d dark:text-green-3df'}`}>{status}</div>
        <div className='text-gray-b0b dark:text-gray-517'>{getTiemString()}</div>
      </div>
    </div>
  );
};

export default ActiveItem;