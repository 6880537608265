/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

export const log = (action: string, params: any) => {
  if (window.gtag) {
    window.gtag("event", action, { ...params });
  }
};
