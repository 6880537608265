// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.

import step2 from "../../assets/celoimages/step2.svg";
import { Step, SmsInput, BottomSheet } from "./components";
import { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { default as store } from "./mobx/CeloSteps";
import { createBot, confirmFirebaseCode } from "../../utils/firbasePhone";
import { observer } from "mobx-react";
import { getErrorMsg } from "../../config/celoErrCode";
import { useTranslation } from "react-i18next";
import { presend } from "../../api/airdropCeloApi";
import { log } from "../../utils/log";

const SmsVerify = () => {
  const [loading, setLoading] = useState(false);
  const [sms, getSms] = useState<any[]>([]);
  const [err, setErr] = useState("");
  const [resentMsg, setResentMsg] = useState("");
  const [countdown, setCountdown] = useState(60);
  const ruoteParams = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const bottomSheetRef: any = useRef<HTMLDivElement | null>();

  let timer: NodeJS.Timeout | null;
  const startCountdown = (num: number) => {
    if (num < 0) return;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      --num;
      setCountdown(num);
      if (num >= 0) startCountdown(countdown);
    }, 1100);
  };

  useEffect(() => {
    startCountdown(countdown);

    return () => {
      if (timer) clearTimeout(timer);
    };
  });

  useEffect(() => {
    if (countdown >= 0) {
      setResentMsg(t("views.smsVerify.sendText") as string);
    } else {
      setResentMsg(t("views.smsVerify.errTips") as string);
    }
    // eslint-disable-next-line
  }, [countdown]);

  const callSms = async (sms: number[]) => {
    if (loading) return;
    setLoading(true);
    setErr("");
    confirmFirebaseCode(sms.join(""))
      .then((res: any) => {
        log("verify_sms_2", { message: "Firebase verified." });
        store
          .accomplishStep2(res._tokenResponse.idToken, {
            slot: ruoteParams.subId as string,
            signature: query.get("signature") as string,
          })
          .then((res) => {
            if (res.status === "checked") {
              log("verify_sms_3", { message: "Accomplish success." });
              // store.accomplishStep3();
              navigate(`/celo/step3/${ruoteParams.id}/${ruoteParams.subId}/?signature=${query.get("signature")}`, {
                replace: true,
              });
            }
          })
          .catch((err: any) => {
            console.warn(err);
            if (err.response.code === 500) {
              setErr(t("views.errorMessage.sysErr") as string);
            } else if (err.response.reason === "NO_STOCK") {
              bottomSheetRef?.current?.showBottomSheet();
            } else {
              setErr(
                err.response?.data?.message ||
                  "Sorry, you are not eligible to join this event."
              );
            }
          });
      })
      .catch((err) => {
        setErr(getErrorMsg(err.message));
        console.warn(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resent = async () => {
    if (countdown >= 0 || loading) return;
    // validate : phone
    const res = await presend({
      campaign_id: store.accountInfo.activityId,
      phone_number: store.accountInfo.callCode + store.accountInfo.phone,
    });
    // Error block
    if (res?.status === 412) {
      // VALIDATE_ERROR
      setErr("Sorry, you are not eligible to join this event.");
      setLoading(false);
      return;
    } else if (res?.status === 421) {
      // TOO_MANY_REQ
      setErr("Too many requests.");
      setLoading(false);
      return;
    }
    createBot(
      "verify-container",
      store.accountInfo.callCode + store.accountInfo.phone.trim()
    ).catch((err) => {
      setErr(err.message);
    });
    setCountdown(60);
    startCountdown(countdown);
  };

  const accountFilter = (account: string) => {
    if (!account) return "";
    return account.slice(0, 8) + "..." + account.slice(-8);
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 pc:bg-promoGradient">
      <div
        className="fixed overflow-y-auto top-0 left-0 right-0 bottom-0 verify-container
   flex flex-col items-center mx-auto px-3
    pc:max-w-404 pc:mt-24px bg-white-fff pc:border-4 pc:mb-80px
    "
      >
        <div className="font-semibold mt-32">
          {t("views.celoairdrop.title", {
            coin_symbol: store.accountInfo?.timeStock?.symbol,
          })}
        </div>
        <Step step={2}></Step>
        <img src={step2} className="mt-5" alt="step 3" />
        <h3 className="text-16 mt-24 text-center">
          {t("views.smsVerify.verTitle")}
        </h3>

        <div className="text-14-22 flex text-left mt-3 text-gray-00006">
          {`${store.accountInfo.callCode} ${store.accountInfo.phone}`}
        </div>
        <div className="px-8">
          <SmsInput
            callSms={callSms}
            loading={loading}
            getSms={(sms: any[]) => {
              const SmsInputed = sms.filter((val) => val === ""),
                SmsInputedLn = SmsInputed.length;
              if (SmsInputedLn > 0 && SmsInputedLn < 6) {
                setErr("");
              }
              getSms(sms);
            }}
          ></SmsInput>
        </div>
        {err ? (
          <div className="text-red-D50000 text-12 text-center">{err}</div>
        ) : (
          <></>
        )}
        <div className="mt-14 text-12 text-gray-00006">
          {resentMsg}&nbsp;
          {countdown < 0 ? (
            <span className="font-bold" onClick={resent}>
              {t("views.smsVerify.sendBtnText")}
            </span>
          ) : (
            <></>
          )}
          &nbsp;
          {countdown >= 0 ? <span>{`${countdown}(s)`}</span> : <></>}
        </div>
        <div className="w-full pt-3 flex flex-col justify-end flex-1 mb-16px">
          <button
            className={`h-48px rounded-full text-14-16 justify-self-end
       w-full flex justify-center items-center pc:max-w-624 ${
         sms.filter((val) => val !== "").length !== 6
           ? "bg-gray-f0f1f2 text-gray-000038"
           : "bg-black-100 text-white-fff"
       }`}
            disabled={
              loading ||
              sms.filter((val) => val !== "").length !== 6 ||
              err !== ""
            }
            onClick={() => {
              if (sms.length !== 6) return;
              callSms(sms);
            }}
          >
            {!loading ? (
              <span>{t("views.smsVerify.verifyBtnTetx")}</span>
            ) : (
              <i className="icon-loading animate-spin"></i>
            )}
          </button>
        </div>
        <BottomSheet ref={bottomSheetRef}>
          <div>
            <h4 className="font-semibold text-base">
              {t("views.celoairdrop.oops")}
            </h4>
            <div className="flex rounded-lg bg-gray-00008 items-center px-3 my-2 py-2">
              <i className="icon-wallet_white mr-8"></i>&nbsp;
              <span className="text-sm">
                {accountFilter(store.accountInfo.account)}
              </span>
            </div>
            <div className="text-sm mb-3">
              {t("views.celoairdrop.progressTip")}
            </div>
            <button
              className="w-full mb-3 rounded-full 
          mt-2 justify-center items-center 
          bg-black-100 text-14-16 h-48px align-middle
           text-white-fff
           "
              onClick={() => {
                bottomSheetRef?.current?.closeBottomSheet();
                window.close();
              }}
            >
              {t("views.celoairdrop.btnGotIt")}
            </button>
          </div>
        </BottomSheet>
      </div>
    </div>
  );
};

export default observer(SmsVerify);
