// Copyright (C) 2022 Opera Norway AS. All rights reserved.
//
// This file is an original work developed by Opera.
import { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";

const List = (props: { selectCountry: (opt: any) => void; list: any[] }) => {
  const { selectCountry, list } = props;

  const ListTag = list.map((item) => (
    <div
      key={item.callCode + item.code}
      className="flex flex-row justify-between items-center mb-12"
      onClick={() => {
        selectCountry(item);
      }}
    >
      <div  className="flex items-center">
        <ReactCountryFlag
          countryCode={item.alpha2Code}
          style={{
            fontSize: 24,
          }}
        />
        &nbsp;&nbsp;&nbsp;
        <span className="text-16 flex flex-wrap">{item.name}</span>
      </div>
      <div className="text-16">{item.callCode}</div>
    </div>
  ));
  return <>{ListTag}</>;
};

const CountryList = (props: {
  selectCountry: (opt: any) => void;
  countryList: any[];
}) => {
  const { selectCountry, countryList } = props;
  const [list, setList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  const inputValue = (e: any) => {
    // setInputValue(e.target.value);
    setLoading(true);
    if (timer) {
      clearTimeout(timer);
    }
    let _timer = setTimeout(() => {
      let q = e.target.value.toLowerCase();
      let _list: any[] = countryList.filter((value) => {
        return (
          value.name.toLowerCase().startsWith(q) || value.callCode.includes(q)
        );
      });
      setList(_list);
      setLoading(false);
    }, 1200);
    setTimer(_timer);
  };
  return (
    <>
      <h3 className="font-medium text-20">{t('components.countryList.title')}</h3>
      <div className="mb-5 my-3 flex items-center h-44px bg-gray-00008 rounded-full">
        &nbsp;&nbsp;<i className="icon-search_gray text-gray-00006"></i>&nbsp;
        <input
          type="text"
          className="bg-transparent text-14-22 text-gray-00006 w-full"
          placeholder="Search country"
          onChange={inputValue}
        />
      </div>
      <div className="h-80 overflow-scroll flex flex-col">
        {loading ? (
          <div className="flex justify-center items-center pt-6">
            <i className="icon-loading animate-spin w-10 flex justify-center items-center"></i>
          </div>
        ) : (
          <List
            selectCountry={selectCountry}
            list={list.length > 0 ? list : countryList}
          ></List>
        )}
      </div>
    </>
  );
};

export default CountryList;
